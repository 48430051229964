.tooltip {
    display: block;
    position: absolute;
    top: 30px;
    z-index: 255;
    background-color: #3E71EC;
    width: 300px;
    height: auto;
    padding: 15px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    animation: tooptipFade 0.3s;
    visibility: visible;

    &--right {
        top: 40px;
        right: 10%;
    }
    &__article {
        display: block;
        max-width:  280px;
        text-wrap: wrap;
    }
}

body:has(#item_01:hover) [aria-describedby="item_01"] {
	display: block;
	visibility: visible;
}

@keyframes tooptipFade {
    from {
        opacity: 0;
        transform: translate3d(0, -10px, 0);
        transition: all .15s ease-in-out;
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@media screen and (max-width: (900px)),
screen and (max-height: (850px)) {
    .tooltip {
        display: none;
    }
}