@import './../../App.scss';

.close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    path {
        transition: fill 0.3s ease-in-out;
    }
    @include onHover {
        path {
        fill: #3E71EC !important;
        transition: fill 0.3s ease-in-out;
       }
    }
    &--danger {
        top: 10px;
        @include onHover {
            path {
            fill: #ad1616 !important;
            transition: fill 0.3s ease-in-out;
            }
        }
    }
}