@import '../../App.scss';

.subscription {
    grid-column: 3 / 8 span;
    margin-left: -20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    background-color: #f4f7ff;

    &::-webkit-scrollbar {
        width: 10px;
        scrollbar-color: #3E71EC #EBF0FF;
        scrollbar-width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #EBF0FF;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #3E71EC;
        border-radius: 3px;
        max-height: 10px;
        min-height: 5px;
        width: 5px;
    }

    &__title {
        font-weight: 700;
        font-size: 22px;
        color: #1e2f4a;
        margin-bottom: 20px;
    }

    .card {
        box-shadow: none;
        background-color: #fff;
        border-radius: 10px 10px 5px 5px;

        &__article {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        &__descr {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 20px;
        }

        &__list {
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 30px;
        }
    }

    .price-list {
        padding-bottom: 20px;
    }

}

@include medium-desktop {
    .subscription {
        overflow-y: auto;
        grid-column: 2 / 9 span;
    }
}

@include large-laptops {
    .subscription {
        grid-column: 2 / 8 span;
    }
    .card {
        grid-column: 3 / 8 span;
        margin-bottom: 20px;
    }
}

@include large-laptops {
    .subscription {
        grid-column: 2 / 8 span;
    }
    .card {
        grid-column: 3 / 8 span;
        margin-bottom: 20px;
    }
}
@include small-dashboard {
    .subscription {
        grid-column: 1 / 12 span;
        grid-row: 3;
        overflow: auto;
        margin-right: 15px;
        &__title {
            padding-left: 10px;
        }
        .card {
            grid-column: 1 / 12 span;
            margin-left: 20px;
        }
    }
}