 @import './mixins/onHover.scss';

// body {
//     overflow-x: hidden;
//     user-select: none;

//     &::-webkit-scrollbar {
//         width: 10px;
//         scrollbar-color: #3E71EC #EBF0FF;
//         scrollbar-width: 5px;
//     }

//     &::-webkit-scrollbar-track {
//         background: #EBF0FF;
//         border-radius: 3px;
//     }

//     &::-webkit-scrollbar-thumb {
//         background-color: #3E71EC;
//         border-radius: 3px;
//         max-height: 10px;
//         min-height: 5px;
//         width: 5px;
//     }
// }

@mixin medium-desktop {
    @media (max-width: (1756px)) {
        @content;
    }
}

@mixin large-laptops {
    @media (max-width: (1445px)) {
        @content;
    }
}

@mixin laptops {
    @media (max-width: (1225px)) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: (1024px)) {
        @content;
    }
}

@mixin small-dashboard {
    @media screen and (max-width: (900px)) {
        @content;
    }
}

@mixin small-tablet {
    @media (max-width: (768px)) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: (576px)) {
        @content;
    }
}

@mixin mini-mobile {
    @media (max-width: (375px)) {
        @content;
    }
}

//global css

 * {
     -webkit-tap-highlight-color: transparent;
 }


 .overflow-hidden {
    overflow: hidden !important;
}

.snackbar-close {
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

#notistack-snackbar {
    font-size: 16px !important;
    padding-right: 15px;
    max-width: 500px;
}

