@import '../../App.scss';

.auth-container {
    position: relative;
    background-image: url('./../../assets/img/auth-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    padding-top: 400px;
    padding-bottom: 400px;
}

.auth {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    width: 678px;
    min-height: 493px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 7px 134px 0 rgba(62, 113, 236, 0.05);

    &__title {
        text-align: center;
        font-weight: 700;
        font-size: 34px;
        line-height: 110%;
        text-transform: uppercase;
        color: #3e5372;
        margin: 0 0 20px;
        cursor: default;
    }

    &__verify {
        display: block;
        width: 295px;
        height: 295px;
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    &__descr {
        margin: 0 auto;
        margin-bottom: 30px;
        width: 350px;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #a8b2c1
    }

    .btn-group {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    &__btn {
        position: relative;
        border: 2px solid #3E71EC;
        border-radius: 5px;
        max-width: 234px;
        width: 100%;
        height: 50px;
        font-weight: 700;
        font-size: 20px;
        color: #3e71ec;
        z-index: 6;
        transition: background-color, color 0.3s ease-in-out;

        &::after {
            z-index: -1;
            transition: opacity 2s linear;
            content: "";
            position: absolute;
            border-radius: 5px;
            margin: 0 auto;
            opacity: 0;
            left: -2px;
            top: -2px;
            bottom: -2px;
            right: -2px;
            background: linear-gradient(90deg, #AA3EEC 9%, #6E8FDD 25%, #6E8FDD 56%, #AA3EEC 96%);
        }

        @include onHover {
            background-color: #3E71EC;
            color: #fff;
        }

        &--filled {
            @include onHover {
                transition: background-color 0.3s ease-in-out;
                background-color: #6E8FDD;
                border: 2px solid #6E8FDD;

                &::after {
                    transition: opacity 2s linear;
                    opacity: 0.7;
                }
            }

            background-color: #3E71EC;
            color: #fff;
        }
    }

    .auth-form {
        &__title {
            margin: 0;
            font-weight: 500;
            font-size: 18px;
            color: #3e5372;
            margin-bottom: 10px;

            &--confirm {
                padding-bottom: 45px;
            }
        }

        &__descr {
            margin: 0 auto;
            margin-bottom: 30px;
            //width: 450px;
            font-weight: 400;
            font-size: 16px;
            text-align: center;
            color: #a8b2c1
        }

        .input-wrapper {
            height: 50px;
            margin-bottom: 15px;
            position: relative;

            &::before {
                background-repeat: no-repeat;
                background-position: center;
                content: "";
                background-image: url("./../../assets/svg/auth-login.svg");
                position: absolute;
                width: 14px;
                height: 18px;
                left: 17px;
                top: 50%;
                transform: translateY(-50%);
            }

            &--login {
                &::before {
                    background-image: url("./../../assets/svg/auth-password.svg");
                    width: 16px;
                    height: 13px;
                }
            }

            &--user {
                &::before {
                    background-image: url("./../../assets/svg/auth-user.svg");
                    width: 13px;
                    height: 14px;
                }
            }

            &--danger {
                border: 1px solid red;
                border-radius: 6px;
            }

            &--confirm {
                position: relative;
                overflow: hidden;

                input {
                    font-weight: 500;
                    font-size: 18px;
                    color: #93a3d0;
                }
                .auth-form__input--colored {
                    color: #617097;
                }

                .confirm-btn {
                    position: absolute;
                    right: 0;
                    border-radius: 5px;
                    width: 140px;
                    height: 50px;
                    background-color: #3e71ec;
                    font-weight: 700;
                    font-size: 18px;
                    color: #fff;


                    &--enterCode {
                        transform: translateX(150%);
                        animation: btnHidden 2s;
                        animation-fill-mode: forwards;
                        display: inline-block;
                        transform: translateY(-150%);
                        user-select: none;
                    }
                }
            }
        }

        &__error {
            display: block;
            margin-top: 5px;
            padding-bottom: 15px;
        }

        &__input {
            outline: none;
            border: none;
            background-color: #e2e7f5;
            border-radius: 5px;
            width: 100%;
            height: 100%;
            font-weight: 500;
            font-size: 18px;
            padding-left: 43px;
            color: #617097;

            &::placeholder {
                color: #93a3d0;
            }
        }

        &__link {
            position: relative;
            margin-top: 0;
            display: inline-block;
            font-weight: 600;
            font-size: 16px;
            color: #a8b2c1;
            cursor: pointer;
            margin-bottom: 40px;

            &::before {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 1px;
                bottom: -3px;
                background-color: #A8B2C1;
                transform: scaleX(0);
                transform-origin: top left;
                transition: transform 0.6s ease;
            }

            @include onHover {
                &::before {
                    transform: scaleX(1);
                }
            }
        }
    }
}

.change-password {
    position: relative;
    padding-top: 50px;
    padding-right: 52px;
    padding-bottom: 50px;
    padding-left: 52px;
    z-index: 5;
    overflow: hidden;
}


@keyframes btnHidden {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(150%);
    }
}

@include small-dashboard {
    .auth {
        width: 95%;
        padding: 30px;
    }
}