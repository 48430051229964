@import './../../App.scss';

.connections {
    position: relative;
    padding-top: 250px;
    padding-bottom: 180px;

    &__title {
        max-width: 850px;
        margin: 0 auto;
        text-align: center;
        font-weight: 700;
        font-size: 116px;
        line-height: 90%;
        text-transform: uppercase;
        color: #3e71ec;
    }

    &__subtitle {
        margin: 0;
        margin-top: 20px;
        text-align: center;
        font-weight: 700;
        font-size: 54px;
        line-height: 110%;
        text-transform: uppercase;
        color: #3e71ec;
    }

    &__descr {
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        color: #617097;
        margin-bottom: 45px;

        &::before {
            content: '';
            display: inline-block;
            margin-right: 17px;
            width: 12px;
            height: 12px;
            border-radius: 100%;
            background-color: #3eec6f;
            box-shadow: 0 0 0px 0 #3eec6f;
            animation: pulse 2s infinite;
        }
    }

    --animation-duration: 300s;

    &__list {
        display: flex;
        animation: run var(--animation-duration) linear infinite reverse;

        &--second {
            animation: run var(--animation-duration) linear infinite;
        }
    }

    &__item {
        display: inline-block;
        border-radius: 47px;
        padding: 6px 25px;
        height: 37px;
        background: #edf3ff;
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        color: #617097;
        margin-right: 15px;
        margin-bottom: 15px;
        min-width: 240px;
        max-width: 240px;
    }

    @media  (max-width: (2525px)) {
        --animation-duration: 500s;
    }

    
    @media  (max-width: (2225px)) {
        --animation-duration: 350s;
    }

    @include laptops {
        padding-bottom: 80px;

        &__title {
            font-size: 45px;
        }

        &__subtitle {
            font-size: 32px;
        }

        &__descr {
            font-size: 16px;
        }
    }


    @include medium-desktop {
        --animation-duration: 300s;
    }

    @include large-laptops {
        --animation-duration: 300s;
    }

    @include laptops {
        --animation-duration: 300s;
    }

    @include small-tablet {
        --animation-duration: 100s;

        &__subtitle {
            font-size: 36px;
            margin-bottom: 30px;
            padding-left: 5px;
            padding-right: 5px;
        }

        &__descr {
            display: none;
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(100, 236, 62, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(100, 236, 62, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(100, 236, 62, 0);
    }
}

@keyframes run {
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(-200vw)); }
}

@keyframes runMobile {
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(-200vw)); }
}