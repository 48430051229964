@import '../../App.scss';

.header {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;

    &__container {
        align-items: center;
        justify-content: space-between;
    }

    .logo {
        display: inline-block;
        background-image: url('./../../assets/svg/kairospeak-logo.svg');
        background-repeat: no-repeat;
        width: 263px;
        height: 28px;
    }

    &__settings {
        display: flex;
        align-items: center;
    }

    &__select {
        border: none;
        background: none;
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        color: #617097;
        margin-right: 25px;
    }

    .custom-select {
        position: relative;
        height: 30px;
        width: 50px;
        user-select: none;
        cursor: pointer;
        font-weight: 500;
        font-size: 20px;
        color: #617097;
        margin-right: 35px;

        &__selected {
            margin-right: 5px;
        }

        .arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -5px;
            width: 16px;
            height: 9px;
            transition: rotate 0.3s;
        }

        .arrow--reversed {
            rotate: 180deg;
        }
    }

    .lang-list {
        position: absolute;
        left: -10px;
        top: -10px;
        background-color: #fff;
        border-radius: 10px;
        text-align: center;
        padding: 10px;

        &__item {
            position: relative;
            font-weight: 500;
            font-size: 20px;
            color: #617097;

            &--none {
                pointer-events: none;
                display: none;
            }

            &:first-child {
                border-bottom: 1px solid #CACADE;
            }
        }
    }

    &__button {
        border: 2px solid #3E71EC;
        border-radius: 5px;
        padding: 12px 65px;
        font-weight: 700;
        font-size: 20px;
        color: #3e71ec;
        transition: background-color, color 0.3s ease-in-out;

        @include onHover {
            background-color: #3E71EC;
            color: #fff;
        }
    }

    &__login {
        display: none;
    }

    @include tablet {
        .logo {
            background-image: url('./../../assets/svg/logoClear.svg');
            width: 50px;
        }
        &__button {
            display: none;
        }

        &__login {
            display: block;
        }
    }

    @include small-tablet {
        .container {
            padding: 0 10px;
        }
    }
}

.nav {
    align-items: center;

    &__list {
        background-color: #fff;
        align-items: center;
        border-radius: 10px;
        //gap: 41px;
        gap: 18px;
    }

    &__item {
        position: relative;
        padding: 17px 0px;
        z-index: 5;
        &--clear {
            padding: 0;
        }
    }
    

    &__item:nth-child(odd) {
        &::after {
            z-index: -1;
            transition: opacity 0.5s ease-in-out;
            content: "";
            position: absolute;
            opacity: 0;
            left: -18px;
            top: 0px;
            bottom: 0px;
            right: -19px;
            background: radial-gradient(circle at center, rgba(255, 255, 255, 1) 20%, #3E71EC 100%);
        }

        @include onHover {
            cursor: pointer;

          &::after {
            transition: opacity 0.6s ease-in-out;
            opacity: 0.3;
          }
        }
    }

    &__link {
        font-weight: 400;
        font-size: 16px;
        color: #3b3b55;

        &--line {
            position: relative;
            flex-grow: 1;

            &::before {
                display: none;
            }

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 16px;
                background: #CACADE;
            }
        }
    }

    &__item:first-child {
        padding: 17px 0px 17px 41px;

        &::after {
            z-index: -1;
            transition: opacity 0.5s ease-in-out;
            content: "";
            position: absolute;
            border-radius: 10px 0 0 10px;
            opacity: 0;
            left: 0px;
            top: 0px;
            bottom: 0px;
            right: -19px;
            color: #AA3EEC;
            //background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
            background: radial-gradient(circle at center, rgba(255, 255, 255, 1) 20%, #3E71EC 100%);
        }

        @include onHover {
            cursor: pointer;

            &::after {
                transition: opacity 0.6s ease-in-out;
                opacity: 0.3;
            }
        }
    }

    &__item:last-child {
        padding: 17px 41px 17px 0px;

        &::after {
            z-index: -1;
            transition: opacity 0.5s ease-in-out;
            content: "";
            position: absolute;
            border-radius: 0px 10px 10px 0px;
            opacity: 0;
            left: -18px;
            top: 0px;
            bottom: 0px;
            right: 0px;
            color: #AA3EEC;
            //background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
            background: radial-gradient(circle at center, rgba(255, 255, 255, 1) 20%, #3E71EC 100%);
        }

        @include onHover {
            cursor: pointer;

            &::after {
                transition: opacity 0.6s ease-in-out;
                opacity: 0.3;
            }
        }
    }

    //@media screen and (max-width: 1870px) {
    //    &__list {
    //        gap: 6px;
    //    }
    //    .nav__link {
    //        font-size: 14px;
    //    }
    //
    //    &__item:nth-child(odd) {
    //        &::after {
    //            z-index: -1;
    //            transition: opacity 0.5s ease-in-out;
    //            content: "";
    //            position: absolute;
    //            opacity: 0;
    //            left: -11px;
    //            top: 0px;
    //            bottom: 0px;
    //            right: -11px;
    //            background: radial-gradient(circle at center, rgba(255, 255, 255, 1) 20%, #3E71EC 100%);
    //        }
    //
    //        &:hover {
    //            cursor: pointer;
    //
    //            &::after {
    //                transition: opacity 0.6s ease-in-out;
    //                opacity: 0.3;
    //            }
    //        }
    //    }
    //
    //    &__item:first-child {
    //        padding: 17px 0px 17px 15px;
    //
    //        &::after {
    //            z-index: -1;
    //            transition: opacity 0.5s ease-in-out;
    //            content: "";
    //            position: absolute;
    //            border-radius: 10px 0 0 10px;
    //            opacity: 0;
    //            left: 0px;
    //            top: 0px;
    //            bottom: 0px;
    //            right: -11px;
    //            color: #AA3EEC;
    //            //background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
    //            background: radial-gradient(circle at center, rgba(255, 255, 255, 1) 20%, #3E71EC 100%);
    //        }
    //
    //        &:hover {
    //            cursor: pointer;
    //
    //            &::after {
    //                transition: opacity 0.6s ease-in-out;
    //                opacity: 0.3;
    //            }
    //        }
    //    }
    //
    //    &__item:last-child {
    //        padding: 17px 15px 17px 0px;
    //
    //        &::after {
    //            z-index: -1;
    //            transition: opacity 0.5s ease-in-out;
    //            content: "";
    //            position: absolute;
    //            border-radius: 0px 10px 10px 0px;
    //            opacity: 0;
    //            left: -11px;
    //            top: 0px;
    //            bottom: 0px;
    //            right: 0px;
    //            color: #AA3EEC;
    //            //background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
    //            background: radial-gradient(circle at center, rgba(255, 255, 255, 1) 20%, #3E71EC 100%);
    //        }
    //
    //        &:hover {
    //            cursor: pointer;
    //
    //            &::after {
    //                transition: opacity 0.6s ease-in-out;
    //                opacity: 0.3;
    //            }
    //        }
    //    }
    //}

    @include large-laptops {
        display: none;
    }

}