.verify>p {
    margin-bottom: 0;
}

.alter-code {
    display: inline-block;
    margin: 0 auto;
    margin-bottom: 45px;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #a8b2c1;

    &__descr {
        text-align: center;
    }

    &__input {
        margin: 0 auto;
        position: relative;
        width: 250px;
        &::after {
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-80%);
            content: url('./../../assets/svg/copyIcon.svg');
            width: 20px;
            height: 20px;
        }
    }

    &__input>input {
        width: 250px;
        outline: none;
        border: none;
        background-color: #e2e7f5;
        border-radius: 5px;
        height: 34px;
        font-weight: 500;
        font-size: 18px;
        padding-left: 13px;
        color: #93a3d0;
        margin-bottom: 10px;
    }
}