.date-picker {
    padding-top: 8px;
    padding-left: 9px;
    width: 282px;
    height: auto;
    background: #ebf0ff;
    cursor: default;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    &__left {
        padding-right: 9px;
        padding-bottom: 3px;
    }

    &__btn {
        font-weight: 400;
        font-size: 14px;
        background-color: #D0DAF7;
        color: #5e779c;
        width: 100%; // Теперь занимает всю ширину родителя
        padding: 3px;
        min-height: 27px;
        box-sizing: border-box; // Гарантирует правильные расчеты ширины с учетом отступов
    }

    &__list {
        position: relative;
        margin-top: 1px;
        height: 15px;
        opacity: 0;
        transform: scaleY(0);
        transition: transform 0.3s ease;
        transform-origin: top center;
        cursor: pointer;
        z-index: 5;

        &--active {
            display: block;
            transition: transform 0.3s ease;
            opacity: 1;
            transform: scaleY(1);
            transform-origin: top center;
        }
    }

    &__item {
        width: 100%; // Убедитесь, что элементы списка также занимают всю ширину
        background-color: #D0DAF7;
        font-weight: 400;
        font-size: 14px;
        color: #5e779c;
        padding: 3px;
        box-sizing: border-box;
    }
}
