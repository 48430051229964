@import '../../App.scss';

.alert {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(10.5px);
    background: rgba(245, 246, 250, 0.91);
    z-index: 150;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.4s ease both;

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 101;
        margin: 0 auto;
        width: 678px;
        border-radius: 5px;
        box-shadow: 0 7px 134px 0 rgba(62, 113, 236, 0.05);
        background: #fff;
    }

    &__descr {
        text-align: center;
        width: 465px;
        margin-bottom: 40px;
    }

    &__btn {
        background: #ff7e7e;
        border-radius: 5px;
        width: 223px;
        height: 50px;
        font-weight: 700;
        font-size: 20px;
        color: #fff;
        margin-bottom: 30px;
        @include onHover {
            transition: background-color 0.3s ease-in-out;
            background-color: #ad1616;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@include small-dashboard {
    .alert {
        position: fixed;
        img {
            top: 10px;
            right: 5px;
        }
        &__content {
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 15px;
            width: 90%;
        }
        &__descr {
            width: 100%;
        }
    }
}