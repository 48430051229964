@import '../../App.scss';

.snackbar {
    z-index: 1000;
    position: absolute;
    height: auto;
    min-height: 125px;
    width: 35%;
    top: 10%;
    right: 0;
    background-color: #43CA6F;
    box-shadow: 0 7px 134px 0 rgba(62, 113, 236, 0.05);
    border-radius: 5px 0 0 5px;
   transform: translateX(100%);
    animation: SnackbarOpen 3s ease-in-out;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    //padding-right: 500px;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
     
    // animation-direction: alternate-reverse;
    &--danger {
        background-color: #FF7E7E;
    }

    &__title {
        padding: 0;
        margin: 0;
        font-weight: 700;
        font-size: 22px;
        color: #fff;
    }

    &__article {
        font-weight: 400;
        font-size: 18px;
        color: #fff;
    }
}

@keyframes SnackbarOpen {
    from {
        transform: translateX(100%);
    }

    30% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(0);
    }

    70% {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

@keyframes SmallSnackbarOpen {
    from {
        transform: translateY(-100%);
    }

    30% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(0);
    }

    70% {
        transform: translateY(0);
    }

    to {
        transform: translateY(-150%);
    }
}

@include small-dashboard {
   .snackbar {
    top: 0%;
    width: 50%;
    left: 25%;
    transform: translate(-50%, -100%);
    border-radius: 0px 0px 5px 5px;
    animation: SmallSnackbarOpen 3s ease-in-out;
    padding-top: 40px;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    text-align: center;
   }
}