@import 'App.scss';

html {
    box-sizing: border-box;
    font-family: "OpenSans";
}

body {
    margin: 0;
        &::-webkit-scrollbar {
        width: 10px;
        scrollbar-color: #3E71EC #EBF0FF;
        scrollbar-width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #EBF0FF;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #3E71EC;
        border-radius: 3px;
        max-height: 10px;
        min-height: 5px;
        width: 5px;
    }
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

.page {
    height: 100%;
    -webkit-text-size-adjust: 100%;
}

.page__body {
    margin: 0;
    min-width: 320px;
    min-height: 100%;
    font-size: 16px;
}

img {
    height: auto;
    //max-width: 100%;
    object-fit: cover;
}

a {
    text-decoration: none;
}

.site-container {
    overflow: hidden; 
}

.is-hidden {
    display: none !important;
}

.btn-reset {
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    font-family: inherit;
}

.list-reset {
    list-style: none;
    margin: 0;
    padding: 0;
}

.input-reset {
    -webkit-appearance: none;
    appearance: none;
    border: none;
    border-radius: 0;
    background-color: #fff;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }
}

.visually-hidden {
    position: absolute;
    overflow: hidden;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
}

.container {
    margin: 0 auto;
    padding: 0 122px;
    max-width: 1920px;
    @include medium-desktop {
        padding: 0 60px;
    }
    @include tablet {
        padding: 0 30px;
    }
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

.centered {
    text-align: center;
}

.dis-scroll {
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    overscroll-behavior: none;
}

.page--ios .dis-scroll {
    position: relative;
}


.flex {
    display: flex;
}

.grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 20px;
}


/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
body, h1, h2, h3, h4, p,
figure, blockquote, dl, dd {
  margin-block-end: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5;
}

/* Set shorter line heights on headings and interactive elements */
h1, h2, h3, h4,
button, input, label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1, h2,
h3, h4 {
  text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: currentColor;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input, button,
textarea, select {
  font-family: inherit;
  font-size: inherit;
}

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}