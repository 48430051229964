@import './../../App.scss';

.hero {
    display: flex;
    justify-content: column;
    padding-top: 208px;
    background: url('./../../assets/img/hero-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &__content {
        grid-column: 2 /10 span;
        padding-left: 35px;
        padding-right: 35px;
    }

    &__title {
        text-align: center;
        margin: 0 auto;
        max-width: 850px;
        font-weight: 700;
        font-size: 116px;
        line-height: 106%;
        text-transform: uppercase;
        color: #3e71ec;
    }

    &__subtitle {
        text-align: center;
        margin: 0 auto;
        max-width: 850px;
        font-weight: 700;
        font-size: 54px;
        line-height: 110%;
        text-transform: uppercase;
        color: #3e71ec;
    }

    &__descr {
        margin: 0 auto;
        max-width: 750px;
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        color: #617097;
        margin-bottom: 60px;
        margin-top: 40px;
    }

    &__descr_paragraph {
        margin: 0;
        max-width: 750px;
    }

    &__button {
        position: relative;
        border: 2px solid #3E71EC;
        border-radius: 5px;
        padding: 12px 57px;
        font-weight: 700;
        font-size: 20px;
        color: #3e71ec;
        z-index: 6;
        transition: background-color, color 0.3s ease-in-out;

        @include onHover {
            background-color: #3E71EC;
            color: #fff;
        }

        &::after {
            z-index: -1;
            transition: opacity 2s linear;
            content: "";
            position: absolute;
            border-radius: 5px;
            margin: 0 auto;
            opacity: 0;
            left: -2px;
            top: -2px;
            bottom: -2px;
            right: -2px;
            background: linear-gradient(90deg, #AA3EEC 9%, #6E8FDD 25%, #6E8FDD 56%, #AA3EEC 96%);
        }

        &--filled {
            @include onHover {
                transition: background-color 0.3s ease-in-out;
                background-color: #6E8FDD;
                border: 2px solid #6E8FDD;

                &::after {
                    transition: opacity 2s linear;
                    opacity: 0.7;
                }
            }

            background-color: #3E71EC;
            color: #fff;
        }
    }

    &__img-wrapper {
        position: relative;
        width: 100%;
        height: 100%;

        &::after {
            content: "";
            z-index: 5;
            width: 9px;
            height: 9px;
            display: inline-block;
            position: absolute;
            background-color: #000;
            border-radius: 50%;
            top: 20px;
            left: 50%;
            transform: translateY(-50%);
        }
    }

    &__img {
        position: relative;
        display: block;
        height: 100%;
        width: 100%;
        object-fit: fill;
        z-index: 1;
        // border-left: 1px solid #8fa7e3;
        // border-right: 1px solid #8fa7e3;
        // border-top: 1px solid #8fa7e3;
        border-radius: 18px 18px 0 0;
    }

    &__minibg {
        display: none;
    }

    @include large-laptops {
        &__title {
            text-align: center;
            font-size: 64px;
        }

        &__descr {
            font-size: 18px;
        }

        &__button {
            padding: 12px 30px;
        }
    }

    @include tablet {
        &__content {
            padding: 0;
        }

        padding-top: 100px;
    }

    @include small-tablet {
        padding-bottom: 250px;

        .container {
            padding: 0 10px;
        }

        &__title {
            display: none;
        }
        &__subtitle {
            font-size: 36px;
            padding-top: 30px;
        }

        &__img {
            display: none;
        }

        &__img-wrapper {
            &::after {
                display: none;
            }
        }

        &__minibg {
            position: relative;
            margin: 0 auto;
            display: block;
            z-index: 1;
            width: 329px;
            height: 497px;
            margin-bottom: 10px;

            &::before {
                content: "";
                width: 8px;
                height: 8px;
                display: inline-block;
                position: absolute;
                background-color: #000;
                border-radius: 50%;
                top: 15px;
                left: 49%;
                transform: translateY(-50%);
            }

            &::after {
                --fade: 70%;
                --gradient-fade: var(--fade) calc(100% - var(--fade));
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 40%;
                background: #fff;
                -webkit-mask: linear-gradient(to top, transparent 0%, black var(--gradient-fade), transparent 100%),
                    linear-gradient(to left, transparent 0%, black var(--gradient-fade), transparent 100%);
                opacity: 0.8;
                z-index: 2;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-left: 1px solid #89a3e1;
                border-right: 1px solid #89a3e1;
                border-top: 1px solid #89a3e1;
                border-radius: 21px;
                z-index: -1;
            }
        }

        &__text {
            position: relative;
            margin-top: -70px;

            &::before {
                content: "";
                z-index: 1;
                position: absolute;
                width: 110%;
                height: 138%;
                top: -40px;
                right: -10px;
                // filter: blur(202.1999969482422px);
                // background: #fff;
                //background-image: radial-gradient(at  center, #fff, rgba(255, 255, 255, 0.9) 55%, transparent 95%);
                background-image: linear-gradient(transparent 10%, #fff 20%, #fff 80%, transparent)
                //background: radial-gradient(circle, rgba(255,255,255, 1) 0%, rgba(255,255,255, 1) 35%, transparent 100%);
                //background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, transparent 100%);
            }

        }

        &__title {
            position: relative;
            z-index: 5;
            font-size: 36px;
        }

        &__subtitle {
            position: relative;
            z-index: 5;
        }

        &__descr {
            position: relative;
            margin-bottom: 40px;
            z-index: 5;
            margin-top: 0px;
            font-size: 18px;
        }

        .btn-group {
            position: relative;

            &::after {
                content: '';
                margin: 0 auto;
                margin-top: 10px;
                width: 0;
                height: 0;
                border: 6px solid transparent;
                border-top: 12px solid #3B4B70;
                border-bottom: 0;

            }
        }
    }
}

.btn-group {
    justify-content: center;
    gap: 25px;
    margin-bottom: 40px;

    @include small-tablet {
        max-width: 223px;
        margin: 0 auto;
        gap: 10px;
        flex-direction: column;
    }
}