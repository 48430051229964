@import '../../App.scss';


.dashboard-container {
    position: relative;
    height: 100vh;
    overflow-y: auto;
    background-color: #f4f7ff;

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        scrollbar-color: #3E71EC #EBF0FF;
        scrollbar-width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #EBF0FF;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #3E71EC;
        border-radius: 3px;
        max-height: 10px;
        min-height: 5px;
        width: 5px;
    }
}


@include small-dashboard {
    .dashboard-container {
       // height: auto;
    }
}

@media screen and (max-width: (900px)) {
    .dashboard-container {
        height: auto;
        overflow: visible;
        padding-bottom: 50px;
        min-height: 100%;
     }
}