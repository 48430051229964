@import '../../App.scss';

.active-sub {
    grid-column: 11 / 2 span;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 3;
    background-color: #fff;
    // overflow-y: auto;
    // &::-webkit-scrollbar {
    //     width: 10px;
    //     scrollbar-color: #3E71EC #EBF0FF;
    //     scrollbar-width: 5px;
    // }

    // &::-webkit-scrollbar-track {
    //     background: #EBF0FF;
    //     border-radius: 3px;
    // }

    // &::-webkit-scrollbar-thumb {
    //     background-color: #3E71EC;
    //     border-radius: 3px;
    //     max-height: 10px;
    //     min-height: 5px;
    //     width: 5px;
    // }
    &__article {
        font-weight: 700;
        font-size: 22px;
        color: #1e2f4a;
        margin-bottom: 60px;
    }

    &__header {
        position: relative;
        border-radius: 5px 5px 0 0;
        background: #b8a9ef;
        height: 48px;
        font-weight: 700;
        font-size: 18px;
        color: #fff;
        padding-left: 15px;
        padding-right: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--TRIAL {
            background-color: #8293c7;
        }

        &--BASIC {
            background-color: #3e71ec;
        }
    }

    &__cancel {
        cursor: pointer;
    }

    &__item {
        margin-bottom: 30px;
    }

    &__content {
        border-radius: 0 0 5px 5px;
        background: #ebf0ff;
        padding-top: 11px;
        padding-left: 15px;
        padding-right: 15px;
    }

    &__descr {
        font-weight: 400;
        font-size: 16px;
        color: #3e5372;
        padding-bottom: 20px;
        border-bottom: 1px solid #d6deee;
        &--no-border {
            border: none;
        }
    }

    p {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        color: #5e769c;
    }

    &__date-from {
        margin-top: 10px;
        font-weight: 700;
        font-size: 16px;
        color: #3e5372;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &__date-to {
        font-weight: 700;
        font-size: 16px;
        color: #3e5372;
        display: flex;
        align-items: center;
        gap: 5px;
        padding-bottom: 20px;
    }
}

.cancel {
    margin-top: auto;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 5px;
    background: #ebf0ff;

    &__article {
        font-weight: 700;
        font-size: 18px;
        color: #3e5372;
        max-width: 160px;
    }

    &__descr {
        font-weight: 400;
        font-size: 16px;
        color: #3e5372;
        padding-bottom: 20px;
    }

    &__button {
        position: relative;
        border: 2px solid #3E71EC;
        border-radius: 5px;
        width: 100%;
        height: 50px;
        font-weight: 700;
        font-size: 20px;
        color: #3e71ec;
        z-index: 6;
        transition: background-color, color 0.3s ease-in-out;

        @include onHover {
            background-color: #3E71EC;
            color: #fff;
    }
    }
}

@include large-laptops {
    .active-sub {
        grid-column: 10 / 3 span;
    }
}

@include small-dashboard {
    .active-sub {
        background-color: #f4f7ff;
        grid-column: 1 / 12 span;
        grid-row: 2;
        padding-left: 10px;
        z-index: 3;

        &__article {
            margin-bottom: 25px;
        }

        .cancel {
            text-align: center;
            margin-bottom: 0;
            &__article {
                max-width: 100%;
                text-align: center;
            }
        }
    }
}