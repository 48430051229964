@import '../../App.scss';

.sidebar {
    // position: fixed;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #3E71EC;
    grid-column: 2 span;
    margin-left: -20px;
    padding-left: 40px;
    padding-top: 33px;
    min-width: 317px;
    max-width: 317px;
    width: 317px;
    max-height: 100%;
    z-index: 5;
    padding-bottom: 300px;
    overflow: hidden;
    z-index: 1;
    user-select: none;
    &__header {
        cursor: pointer;
    }

    &__content {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        margin-left: -20px;
        padding-left: 40px;
        padding-top: 33px;
        min-width: 317px;
        max-width: 317px;
        width: 317px;
        overflow: hidden;
    }

    &__list {
        padding-top: 76px;
        padding-left: 24px;
    }

    &__item {
        position: relative;
        display: inline-block;
        gap: 15px;
        cursor: pointer;
        font-weight: 400;
        font-size: 20px;
        color: #b9ccf8;
        //min-height: 67px;
        margin-bottom: 40px;
        padding-left: 44px;
        transition: color 0.3s;

        @include onHover {
            transition: color 0.3s;
            color: #fff;

            &::before {
                background-color: #fff;
            }
        }

        &::before {
            display: inline-block;
            content: "";
            transition: background-color 0.3s;
            background-color: #b9ccf8;
            position: absolute;
            left: 4px;
        }

        &:nth-child(1):before {
            -webkit-mask: url('./../../assets/svg/dashboard/notifications.svg') no-repeat 50% 50%;
            mask: url('./../../assets/svg/dashboard/notifications.svg') no-repeat 50% 50%;
            width: 20px;
            height: 23px;
            margin-left: 4px;
            margin-right: 4px;
            margin-top: 5px;
        }

        .subs {
            position: absolute;
            left: 4px;
            top: 50%;
            transform: translateY(-50%);

            &--active {
                rect {
                    transition: fill 0.3s;
                    fill: #fff !important;
                }
            }
        }

        .subs:hover {
            rect {
                transition: fill 0.3s;
                fill: #fff !important;
            }
        }

        &:nth-child(2):hover {
            .subs {
                rect {
                    transition: fill 0.3s;
                    fill: #fff !important;
                }
            }
        }

        // &:nth-child(2):before {
        //     -webkit-mask: url('./../../assets/svg/dashboard/subscriptions.svg') no-repeat 50% 50%;
        //     mask: url('./../../assets/svg/dashboard/subscriptions.svg') no-repeat 50% 50%;
        //     width: 29px;
        //     height: 21px;
        //     margin-top: 4px;
        // }

        &:nth-child(3):before {
            -webkit-mask: url('./../../assets/svg/dashboard/settings.svg') no-repeat 50% 50%;
            mask: url('./../../assets/svg/dashboard/settings.svg') no-repeat 50% 50%;
            //content: url('./../../assets/svg/dashboard/settings.svg');
            width: 29px;
            height: 30px;
        }

        &:nth-child(4):before {
            -webkit-mask: url('./../../assets/svg/dashboard/telegram.svg') no-repeat 50% 50%;
            mask: url('./../../assets/svg/dashboard/telegram.svg') no-repeat 50% 50%;
            //content: url('./../../assets/svg/dashboard/telegram.svg');
            width: 32px;
            height: 32px;
        }
        &--active {
            transition: color 0.3s;
            color: #fff;
    
            &::before {
                background-color: #fff;
            }
        }
    }

    &__logout {
        position: relative;
        display: block;
        padding-left: 88px;
        margin-top: auto;
        margin-bottom: 127px;
        cursor: pointer;
        //max-width: 150px;
        width: 100%;

        &::before {
            display: inline-block;
            content: '';
            background-color: #b9ccf8;
            transition: background-color 0.3s;
            -webkit-mask: url('./../../assets/svg/dashboard/logout.svg') no-repeat 50% 50%;
            mask: url('./../../assets/svg/dashboard/logout.svg') no-repeat 50% 50%;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            left: 50px;
            width: 20px;
            height: 20px;
        }

        @include onHover {
            &::before {
                background-color: #fff;
            }

            .sidebar__link {
                color: #fff;
            }
        }
    }

    &__link {
        font-weight: 400;
        font-size: 20px;
        color: #b9ccf8;
        transition: color 0.3s;
        // &:hover {
        //     color: #fff;
        // }
    }
}

.app-guide {
    position: absolute;
    bottom: 0;
    //left: 34px;
    bottom: 30px;
    background-color: #b9ccf8;
    color: #3E71EC;
    border-radius: 50%;
    /* one half of ( (border * 2) + height + padding ) */
    height: 32px;
    width: 32px;
    font-weight: 700;
    padding: 4px 3px 0 3px;
    margin-left: 108px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #fff;
    }
}

@include medium-desktop {
    .sidebar {
        max-width: 110px;
        min-width: 110px;
        overflow: hidden;
        padding-left: 30px;
        grid-column: 1 span;
        &__content {
            max-width: 110px;
            min-width: 110px;
            padding-left: 30px;
        }
        &__header {
            margin: 0 auto;
            padding-right: 10px;
            cursor: pointer;

            &::after {
                display: block;
                width: 100%;
                content: url('./../../assets/svg/dashboard/logoicon.svg');
            }
        }

        &__logo {
            display: none;
        }

        &__item {
            padding-left: 100px;
            margin-bottom: 60px;
            padding-bottom: 5px;
            &:first-child {
                margin-bottom: 40px;
            }

            &:nth-child(n)::before {
                transform: scale(1.3);
            }
        }

        .subs {
            transform: scale(1.3);
        }

        &__list {
            padding-top: 76px;
            padding-left: 18px;
        }

        &__logout {
            padding-left: 100px;

            &::before {
                left: 22px;
                transform: scale(1.5);
            }
        }
    }

    .app-guide {
        margin-left: 18px;
    }
}

// @media screen and (max-width: (1240px)) {
//     .sidebar {
//         &__header {
//             padding-right: 20px;
//         }

//         &__list {
//             padding-top: 76px;
//             padding-left: 10px;
//         }

//         &__logout {
//             padding-left: 100px;

//             &::before {
//                 left: 16px;
//                 transform: scale(1.5);
//             }
//         }
//     }

//     .app-guide {
//         margin-left: 14px;
//     }
// }

// @media screen and (max-width: (1120px)) {
//     .sidebar {
//         &__header {
//             padding-right: 30px;
//         }

//         &__list {
//             padding-top: 76px;
//             padding-left: 6px;
//         }

//         &__logout {
//             padding-left: 90px;

//             &::before {
//                 left: 10px;
//                 transform: scale(1.5);
//             }
//         }
//     }

//     .app-guide {
//         margin-left: 6px;
//     }
// }

// @media screen and (max-width: (1020px)) {
//     .sidebar {
//         padding-left: 27px;

//         &__header {
//             padding-right: 40px;
//         }

//         &__list {
//             padding-top: 76px;
//             padding-left: 2px;
//         }

//         &__logout {
//             padding-left: 90px;

//             &::before {
//                 left: 8px;
//                 transform: scale(1.5);
//             }
//         }
//     }

//     .app-guide {
//         margin-left: 5px;
//     }
// }

@media screen and (max-width: (900px)) {
    .sidebar {
        display: none;
    }
}

// @media screen and (max-height: (850px)) {
//     .sidebar {
//         display: none;
//     }
// }