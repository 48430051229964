@import '../../App.scss';

.telegram-bot-page {
    position: relative;
    grid-column: 3 / 12 span;
    display: flex;
    justify-content: space-between;
    // &__sidebar {
    //     grid-column: 9 / 12;
    // }
    .telegram-bot {
    &__content {
        &::before {
            content: 'Coming soon...';
            position: absolute;
            left: 50%;
            top: 50%;
            right: 0;
            bottom: 0;
            transform: translate(-50%,-25%);
            font-weight: 700;
            font-size: 42px;
            text-transform: uppercase;
            text-align: center;
            color: #384f86;
        }
    }
}
}

.mobile-content {
    height: 100vh;
    width: 100%;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.telegram-bot-sidebar {
    width: 326px;
    position: relative;
    // &::before {
    //         display: block;
    //         content: '';
    //         width: 100%;
    //         height: 100%;
    //         position: absolute;
    //         top: 0;
    //         bottom: 0;
    //         background-image: url('./../../assets/img/sidebar-cooming-soon.png');
    //         background-repeat: no-repeat;
    //         background-size: contain;
    // }
}


@include medium-desktop {
    .telegram-bot-page {
        flex-wrap: wrap;
        grid-column: 2 / 12 span;
    }
    .telegram-bot-sidebar {
        width: 1080px;
        border-radius: 15px;
        overflow: hidden;
        // &::before {
        //     background-image: url('./../../assets/img/sidebar-coming-soon-wide.png');
        //     background-size: cover;
        // }
    }
}

@include small-dashboard {
    .telegram-bot-page {
        grid-column: 1 / 12 span;
    }
}
@include mobile {
    .telegram-bot-page {
        grid-column: 1 / 12 span;
    }
}