@import '../../App.scss';

.tableRow {
    position: relative;
    background-color: #fff;

    &__active>span {
        padding-top: 1px;
        padding-bottom: 1px;
        padding-left: 5px;
        padding-right: 5px;
        background-color: #6BFF95;
    }

    &__inActive>span {
        padding-top: 1px;
        padding-bottom: 1px;
        padding-left: 5px;
        padding-right: 5px;
        background-color: #FF7E7E;
    }

    &__btn {
        display: none;
    }

    &--hidden {
        display: none;
    }

    th:last-child {
        position: relative;

        &::after {
            display: block;
            //cursor: pointer;
            content: "";
            //background-color: #C0C9E3;
            //position: absolute;
            top: 50%;
            right: 10px;
            //transform: translateY(-50%);
            width: 16px;
            height: 16px;
            //mask: url('./../../assets/svg/dashboard/pin.svg') no-repeat center;
            //-webkit-mask: url('./../../assets/svg/dashboard/pin.svg') no-repeat center;
        }

        // &:hover::after {
        //     background-color: #FF7E7E;
        // }
    }

    .active {
        &::after {
            background-color: #FF7E7E !important;
        }

        &:hover::after {
            background-color: red !important;
        }
    }

    @include onHover {
        cursor: pointer;
        background-color: #f3f3f3;
    }
}



.content-blur {
    position: relative;
    max-width: 100%;
    height: fit-content;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        backdrop-filter: blur(23.600000381469727px);
        background: rgba(243, 246, 255, 0.01);
        z-index: 5;
    }

    &::after {
        content: "Available only with premium subscription";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 6;
        font-weight: 700;
        font-size: 24px;
        text-transform: uppercase;
        text-align: center;
        color: #384f86;
    }
    &__btn {
        display: none;
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 6;
    }
}

.pin {
    width: 30px;
    z-index: 100;
}

.premium-info {

    &--close {
        animation: infoAppear 1s reverse;
    }

    // &--mobile {
    //     display: none;
    // }
    .mobile-info {
        display: none;
    }

    .tableRow-premium {
        text-align: start;
        z-index: 1;
        position: relative;
        margin-top: 10px;
        background: #ebf0ff;
        border-radius: 5px;
        animation: infoAppear 2s;

        &__article {
            padding-top: 20px;
            padding-left: 20px;
            font-weight: 900;
            font-size: 18px;
            color: #3e5372;
        }

        &__flex {
            display: flex;
            padding-bottom: 20px;

            p {
                width: 45%;
            }
        }

        &__descr {
            padding-left: 20px;
        }

        &:nth-child(3) {
            p:last-child {
                padding-bottom: 10px;
            }
        }
    }
}



@include small-dashboard {
    .tableRow {
        position: relative;
        display: block !important;
        height: auto !important;
        box-shadow: 0 0 35px 0 #eff3fb;
        background: #fff;
        margin-top: 10px;
        margin-bottom: 30px;
        padding: 20px;

        th {
            font-weight: 400;
            font-size: 16px;
            color: #5e779c;
            display: flex;
            justify-content: space-between;

            &::before {
                font-weight: 700;
                font-size: 16px;
                color: #3e5372;
            }
        }

        th:nth-of-type(1):before {
            content: "Grade:";
        }

        th:nth-of-type(2):before {
            content: "Date";
        }

        th:nth-of-type(3):before {
            content: "Coin pair:";
        }

        th:nth-of-type(4):before {
            content: "Buy:";
        }

        th:nth-of-type(5):before {
            content: "Sell";
        }

        th:nth-of-type(6):before {
            content: "To spend:";
        }

        th:nth-of-type(7):before {
            content: "Profit:";
        }

        th:nth-of-type(8):before {
            content: "D/W available:";
        }

        th:nth-of-type(9):before {
            content: "Verified:";
        }

        th:nth-of-type(10):before {
            content: "Opened:";
        }

        // th:last-child {
        //     display: block;
        //     pointer-events: all;
        //     width: auto;
        //     height: auto;
        //     position: absolute;
        //     top: 0;
        //     right: 45%;
        //     transform: translateX(50%);
        //     &::after {
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //     }

            // &::before {
            //     position: absolute;
            //     top: -25px;
            //     content: '';
            //     display: flex;
            //     align-items: center;
            //     justify-content: center;
            //     width: 51px;
            //     height: 51px;
            //     right: 45%;
            //     transform: translateX(15%);
            //     border-radius: 100%;
            //     background-color: #fff;
            //     border: 2px solid #adbcdf;
                // background-repeat: no-repeat;
                // background-position: center;
                // background-image: url('./../../assets/svg/dashboard/pin.svg');
            // }
        // }
    }
}

//animation open-close notification-tab

// @keyframes infoAppear {
//     from {
//         z-index: -1;
//         transform: translateY(-100%);
//         opacity: 0;
//     }

//     to {
//         z-index: 1;
//         transform: translateY(0);
//         opacity: 1;
//     }
// }


// @keyframes hideInfo2 {
//     from {
//         z-index: 1;
//         transform: translateY(0);
//         opacity: 1;
//     }

//     to {
//         z-index: -1;
//         transform: translateY(-100%);
//         opacity: 0;
//     }
// }

@include small-dashboard {
    .tableRow {
        pointer-events: none;

        &__btn {
            display: block;
            border-radius: 5px;
            width: 100%;
            height: 50px;
            background: #3e71ec;
            font-weight: 700;
            font-size: 20px;
            color: #fff;
            margin-top: 21px;
            pointer-events: all;
        }
        
    &--hidden {
        display: block;
    }
    }

    .content-blur {
        height: 100%;
        &__btn {
            display: block;
        }
    }
    .premium-info {
        position: relative;

        &--close {
            animation: none;
        }

        .mobile-info {
            display: flex;
        }

        .tableRow-premium {
            display: none;
            animation: none;
        }
    }

    .premium-info--mobile {
        display: flex;
    }

    .mobile-info {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        //padding-top: 50px;
        
        height: 100%;
        width: 100%;
        overflow: scroll;
        background-color: #fff;
        z-index: 157;
        &__content {
            padding-left: 10px;
            padding-right: 10px;
        }
        &__title {
            padding: 0;
            margin-top: 30px;
            font-weight: 700;
            font-size: 18px;
            color: #1e2f4a;
        }

        &__article {
            padding-top: 20px;
            font-weight: 700;
            font-size: 16px;
            color: #3e5372;
            margin-bottom: 15px;
            &--large {
                width: 100%;
                margin: 0;
                padding: 0;
                background: #3e71ec;
                font-size: 24px;
                color: #fff;
                padding: 20px;
                border-radius: 0px 0px 10px 10px;
                text-align: center;
                margin-bottom: 5px;
            }
        }

        &__block {
            background-color: #ebf0ff;
            padding: 5px;
            border-radius: 10px;
            margin-bottom: 5px;
        }

        &__descr {
            padding: 0;
            margin-bottom: 5px;
            font-weight: 400;
            font-size: 16px;
            color: #5e779c;
        }

        &__btn {
            display: block;
            margin: 0 auto;
            margin-top: auto;
            margin-bottom: 20px;
            border-radius: 5px;
            width: 315px;
            min-height: 50px;
            background: #3e71ec;
            font-weight: 700;
            font-size: 20px;
            color: #fff;
        }
    }
}