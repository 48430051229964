@import './../../App.scss';

.features {
    padding-top: 22px;
    grid-column: 12 span;

    &__title {
        max-width: 750px;
        margin: 0 auto;
        font-weight: 700;
        font-size: 116px;
        line-height: 90%;
        text-transform: uppercase;
        text-align: center;
        color: #3e71ec;
        margin-bottom: 10px;
    }

    &__subtitle {
        margin: 0;
        font-weight: 700;
        font-size: 54px;
        margin-top: 15px;
        text-transform: uppercase;
        text-align: center;
        color: #3e71ec;
        margin-bottom: 120px;
    }

    &__descr {
        margin: 0;
        display: none;
    }

    &__list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 100px;
    }

    &__btn {
        display: none;
    }

    @include tablet {
        margin-bottom: 20px;

        &__title {
            font-size: 100px;
        }

        &__subtitle {
            font-size: 63px;
            margin: 0;
        }

        &__descr {
            color: #617097;
            text-align: center;
            display: block;
            font-size: 20px;
            margin-top: 15px;
            margin-bottom: 120px;
        }
    }

    @include small-tablet {
        &__title {
            font-size: 54px;
        }

        &__subtitle {
            font-size: 36px;
        }
        &__btn {
            margin: 0 auto;
            margin-top: 25px;
            display: block;
            position: relative;
            border-radius: 5px;
            width: 223px;
            height: 55px;
            background: #8293c7;
            font-weight: 700;
            font-size: 20px;
            color: #fff;
            transition: padding 0.4s ease-in-out;
            overflow: hidden;
    
            &::after {
                z-index: 3;
                position: absolute;
                top: 56%;
                transform: translateY(-50%);
                right: -35px;
                transition: right 0.4s ease-in-out;
                content: url("./../../assets/svg/btn-arrow.svg")
            }
    
            @include onHover {
                padding-right: 25px;
    
                &::after {
                    right: 20px;
                }
            }
    
            &::before {
                z-index: 2;
                transition: opacity 1s ease-in-out;
                content: "";
                position: absolute;
                border-radius: 5px;
                opacity: 0;
                left: -2px;
                top: -2px;
                bottom: -2px;
                right: -2px;
                background: linear-gradient(90deg, rgba(110, 143, 221, 0.1) 3%, rgba(255, 255, 255, 0.4) 25%, rgba(255, 255, 255, 0.3) 66%, rgba(255, 255, 255, 0.8) 96%);
            }
    
            @include onHover {
                background-color: #6E8FDD;
    
                &::before {
                    transition: opacity 1s ease-in-out;
                    opacity: 0.7;
                }
            }
    
        }
    }
}

.feature {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    min-height: 278px;
    min-width: 400px;
    max-width: 400px;
    border-radius: 5px;
    margin-right: 17px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #965aec;

    &::before {
        content: "";
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -50px;
        left: 50%;
        width: 109px;
        height: 109px;
        transform: translateX(-50%);
        border-radius: 100%;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__article {
        margin: 0;
        text-align: center;
        padding: 90px 50px 30px 50px;
        font-weight: 700;
        font-size: 32px;
        line-height: 130%;
        text-transform: uppercase;
        color: #fff;
    }

    &__descr {
        margin: 0;
        padding: 0px 29px;
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        color: #e0cdfa;
        margin-bottom: 30px;
    }

    &:nth-child(1) {
        background-color: #965aec;
        background: hsla(277, 82%, 58%, 1);
        background: radial-gradient(circle, hsla(277, 82%, 58%, 1) 58%, hsla(277, 81%, 71%, 1) 100%);
        background: -moz-radial-gradient(circle, hsla(277, 82%, 58%, 1) 58%, hsla(277, 81%, 71%, 1) 100%);
        background: -webkit-radial-gradient(circle, hsla(277, 82%, 58%, 1) 58%, hsla(277, 81%, 71%, 1) 100%);

        &::before {
            background-image: url('./../../assets/svg/feature-1.svg');
            box-shadow: 0 7px 134px 0 rgba(142, 131, 233, 0.68);
        }
    }

    &:nth-child(2) {
        background: hsla(267, 82%, 58%, 1);
        background: hsla(267, 82%, 58%, 1);
        background: radial-gradient(circle, hsla(267, 82%, 58%, 1) 58%, hsla(267, 81%, 71%, 1) 100%);
        background: -moz-radial-gradient(circle, hsla(267, 82%, 58%, 1) 58%, hsla(267, 81%, 71%, 1) 100%);
        background: -webkit-radial-gradient(circle, hsla(267, 82%, 58%, 1) 58%, hsla(267, 81%, 71%, 1) 100%);

        &::before {
            background-image: url('./../../assets/svg/feature-2.svg');
            box-shadow: 0 7px 134px 0 rgba(134, 115, 235, 0.68);
        }
    }

    &:nth-child(3) {
        background: hsla(281, 82%, 58%, 1);
        background: radial-gradient(circle, hsla(281, 82%, 58%, 1) 58%, hsla(281, 81%, 71%, 1) 100%);
        background: -moz-radial-gradient(circle, hsla(281, 82%, 58%, 1) 58%, hsla(281, 81%, 71%, 1) 100%);
        background: -webkit-radial-gradient(circle, hsla(281, 82%, 58%, 1) 58%, hsla(281, 81%, 71%, 1) 100%);

        &::before {
            background-image: url('./../../assets/svg/feature-3.svg');
            box-shadow: 0 7px 134px 0 rgba(149, 122, 234, 0.68);
        }
    }

    &:nth-child(4) {
        margin: 0;
        background: hsla(0, 82%, 58%, 1);
        background: radial-gradient(circle, hsla(0, 82%, 58%, 1) 58%, hsla(0, 81%, 71%, 1) 100%);
        background: -moz-radial-gradient(circle, hsla(0, 82%, 58%, 1) 58%, hsla(0, 81%, 71%, 1) 100%);
        background: -webkit-radial-gradient(circle, hsla(0, 82%, 58%, 1) 58%, hsla(0, 81%, 71%, 1) 100%);

        &::before {
            background-image: url('./../../assets/svg/feature-4.svg');
            box-shadow: 0 7px 134px 0 rgba(207, 78, 99, 0.68);
        }
    }

    &:nth-child(5) {
        margin-left: 17px;
        background: hsla(335, 82%, 58%, 1);
        background: radial-gradient(circle, hsla(335, 82%, 58%, 1) 58%, hsla(334, 81%, 71%, 1) 100%);
        background: -moz-radial-gradient(circle, hsla(335, 82%, 58%, 1) 58%, hsla(334, 81%, 71%, 1) 100%);
        background: -webkit-radial-gradient(circle, hsla(335, 82%, 58%, 1) 58%, hsla(334, 81%, 71%, 1) 100%);

        &::before {
            background-image: url('./../../assets/svg/feature-5.svg');
            box-shadow: 0 7px 134px 0 rgba(207, 92, 165, 0.68);
        }
    }

    &:nth-child(6) {
        background: radial-gradient(ellipse at top, #E0CDFA, #E93EEC);
        background: hsla(299, 82%, 58%, 1);
        background: radial-gradient(circle, hsla(299, 82%, 58%, 1) 58%, hsla(299, 81%, 75%, 1) 100%);
        background: -moz-radial-gradient(circle, hsla(299, 82%, 58%, 1) 58%, hsla(299, 81%, 75%, 1) 100%);
        background: -webkit-radial-gradient(circle, hsla(299, 82%, 58%, 1) 58%, hsla(299, 81%, 75%, 1) 100%);

        &::before {
            background-image: url('./../../assets/svg/feature-6.svg');
            box-shadow: 0 7px 134px 0 rgba(204, 72, 236, 0.68);
        }
    }

    @include tablet {
        min-width: 355px;
        max-width: 355px;
        //max-height: 278px;

        &__article {
            font-size: 27px;
        }

        &__descr {
            font-size: 18px;
        }
    }

    @include small-tablet {
        margin-right: 0;
        margin-left: 0;
        &:nth-child(n) {
            margin-right: 0;
            margin-left: 0;
        }
    }
}