.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(10.5px);
    background: rgba(245, 246, 250, 0.91);
    z-index: 150;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.4s ease both;

    &__content {
        overflow: hidden;
        z-index: 101;
        margin: 0 auto;
        width: 60%;
        max-width: 750px;
        //min-height: 463px;
        border-radius: 5px;
        box-shadow: 0 7px 134px 0 rgba(62, 113, 236, 0.05);
        background: #fff;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


@media screen and (max-width: (900px)) {
    .modal {
        &__content {
        width: 90%;
        }
    }
}
