@import '../../App.scss';

.notification {
    grid-column: 3 / 8 span;
    margin-left: -25px;
    padding-left: 20px;
    padding-right: 20px;
    //padding-bottom: 20px;
    min-width: 1260px;
    //padding-left: 300px;
    background-color: #f4f7ff;
    position: relative;
    z-index: 2;

    .notification-mobile {
        display: none;
    }

    &--wide {
        grid-column: 3 / 12 span;
        padding-left: 20px;
        margin-left: -20px;

        .dropdown {
            //width: 350px;
            //width: 30%;
            flex-basis: 30%;
            &__item {
               // width: 350px;
               width: 100%;
            }

            &__btn {
                //width: 350px;
                width: 100%;
            }

            .date-picker {
               // width: 350px;
               width: 100%;
            }
        }

        .filter-profit {
            &__counter {
                //width: 165px;
            }
        }
    }

    &--wide>.navigation {
        padding-right: 80px;
    }

    &__title {
        font-weight: 700;
        font-size: 22px;
        color: #1e2f4a;
        margin-bottom: 20px;

        &--wide {
            // padding-top: 15px;
        }
    }

    &__table {
        width: 100%;
        max-height: 900px;
        min-height: 600px;
        border-radius: 5px;
        background-color: #fff;
    }

    &__connect {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__descr {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #5e779c;

        &::before {
            content: '';
            display: inline-block;
            margin-right: 7px;
            width: 12px;
            height: 12px;
            border-radius: 100%;
            background-color: #3eec6f;
            box-shadow: 0 0 0px 0 #3eec6f;
            animation: pulse 2s infinite;
        }

        &--failed {
            &::before {
                content: '';
                ;
                background-color: #ec3e3e;
                box-shadow: 0 0 0px 0 #ec3e3e;
                animation: pulseFailed 2s infinite;
            }
        }
    }

    .notification-mobile-header {
        display: none;
    }

    .notification-mobile-navigation {
        display: none;
    }
}

.notifications-empty {
    padding-top: 20px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    min-height: 500px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow:0 0 35px 0 #eff3fb;
    &__descr {
        margin: 0;
        padding: 0;
        padding-left: 58px;
        padding-right: 58px;
        font-weight: 400;
        font-size: 20px;
        color: #697798;
        &:last-child {
            border-bottom: 1px solid #d6deee;
            padding-left: 43px;
            padding-right: 43px;
            padding-bottom: 40px;
            margin-left: 15px;
            margin-right: 15px;
        }
    }
}

.table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;

    th {
        font-weight: inherit;
        user-select: none;
    }

    tr {
        font-weight: inherit;
    }

    tr {
        height: 74px;
    }

    &__header {
        position: sticky;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        color: #3e5372;
        right: 50px;
        border-bottom: 2px solid #e2e7f5;

        .sort {
            position: relative;
            // &::after {
            //     display: inline-block;
            //     cursor: pointer;
            //     margin-left: 5px;
            //     height: 12px;
            //     width: 9px;
            //     content: url('./../../assets/svg/dashboard/sortarrow.svg');
            // }
            &--active {
                svg {
                        path {
                            stroke: #3E71EC;
                        }
                }

            }
            svg {
                width: 12px;
                height: 13px;
                cursor: pointer;
                margin-left: 5px;
                @include onHover {
                    svg {
                        fill: #3E71EC;
                    }
                    path {
                        stroke: #3E71EC;
                    }
                }
            }
        }

        th:last-child {
            width: 42px;
            border-bottom: 2px solid #fff;
        }
    }

    &__body {
        padding-right: 23px;
        display: block;
        height: 705px;
        padding-bottom: 10px;
        overflow-y: scroll;
        margin-right: 10px;

        &::-webkit-scrollbar {
            width: 10px;
            scrollbar-color: #3E71EC #EBF0FF;
            scrollbar-width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #EBF0FF;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #3E71EC;
            border-radius: 3px;
            max-height: 10px;
            min-height: 5px;
            width: 5px;
        }

        tr {
            border-bottom: 1px solid #e2e7f5;
            font-weight: 400;
            font-size: 16px;
            text-align: center;
            color: #5e779c;
            z-index: 3;

            &.animate {
                animation: notificationAppearance 1.5s linear;
            }
        }
    }

    thead,
    tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
        /* even columns width , fix width of table too*/
    }
}

.pagination {
    padding: 0;
    // width: 100%;
    width: fit-content;
    max-width: 400px;
    list-style-type: none;
    background-color: #fff;
    border-radius: 5px;
    // width: 50%;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    height: 33px;
    
    overflow: hidden;
}

.page-item {
    padding: 0;
    margin: 0;
    // width: 50%;
    width: 36px;
    min-width: 36px;
    height: 100%;
    line-height: 200%;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    color: #5E779C;
    a {
        display: block;
        width: 100%;
        height: 100%;
        user-select: none;
        border-right: 1px solid #e2e7f5;
    }
   
    &:nth-last-child(-n+2) {
        a {
        border-right: none;
        }
    }
}
.activepage {
    background-color: #3E71EC;
    color: #fff;
    font-size: 400;
}

.ellipsis {
    // width: 200%;
    width: 72px;
    height: 100%;
    text-align: center;
    cursor: pointer;
    border-right: 1px solid #e2e7f5;
    a {
        display: block;
        width: 100%;
        height: 100%;
        user-select: none; 
    }
}

.pagination:last-child .page-item:last-child {
    background-color: red !important;
}


.btn-none {
    display: none;
}

@keyframes notificationAppearance {
    from {
        z-index: 2;
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        z-index: 3;
        transform: translateY(0);
        opacity: 1;
    }
}

.applyed-filters {
    flex-grow: 3;

    &__item {
        margin-left: 15px;
        margin-top: 5px;
        margin-bottom: 5px;
        display: inline-flex;
        justify-content: space-between;
        align-items: baseline;
        gap: 5px;
        background-color: #3E71EC;
        padding: 3px;
        padding-left: 5px;
        border-radius: 10px;
        color: #fff;
    }

    &__img {
        cursor: pointer;
        display: block;
        width: 16px;
        height: 13px;
        margin-right: 3px;
        object-fit: contain;
        padding-top: 1px;
    }
}

// @media screen and (max-width: (1900px)) {
//     .notification {
//         margin-left: 25px;
//         padding-left: 250px;
//         &--wide {
//             padding-left: 50px;
//         }
//     }
// }

// @media screen and (max-width: (1850px)) {
//     .notification {
//         padding-left: 160px;
//         &--wide {
//             padding-left: 120px;
//         }
//     }
// }
// @media screen and (max-width: (1850px)) {
//     .notification {
//         padding-left: 160px;
//         &--wide {
//             padding-left: 120px;
//         }
//     }
// }


// @media screen and (max-width: (1750px)) {
//     .notification {
//         margin-left: 0px;
//         // padding-left: 100px;
//     }
// }

// @media screen and (max-width: (1800px)) {
//     .notification {
//         &--wide {
//             .dropdown {
//                 width: 300px;

//                 &__item {
//                     width: 300px;
//                 }

//                 &__btn {
//                     width: 300px;
//                 }

//                 .date-picker {
//                     width: 300px;
//                 }
//             }

//             .filter-profit {
//                 &__counter {
//                     width: 145px;
//                 }
//             }
//         }
//     }
// }

// @media screen and (max-width: (1400px)) {
//     .notification {
//         &--wide {
//             .dropdown {
//                 width: 220px;

//                 &__item {
//                     width: 220px;
//                 }

//                 &__btn {
//                     width: 220px;
//                 }

//                 .date-picker {
//                     width: 220px;
//                 }
//             }

//             .filter-profit {
//                 &__counter {
//                     width: 90px !important;

//                     input {
//                         width: 90px;
//                     }
//                 }
//             }
//         }
//     }
// }




@include medium-desktop {
    .notification {
        grid-column: 2 / 9 span;

        &--wide {
            grid-column: 2 / 12 span;
        }
    }

    .notification>.navigation>.nav {
        display: block;
    }
}


//@include tablet {
@media screen and (max-width: (900px))
// ,
// screen and (max-height: (850px))
 {

    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
        box-sizing: border-box;
    }

    .notification {
        &__table {
            //height: 100%;
           // max-height: 100%;
            display: block;
            box-sizing: border-box;
        }
    }

    .table__body {
        padding-left: 23px;
        padding-right: 23px;
        padding-top: 20px;
        height: 650px;
    }

    .table__header {
        display: none !important;
    }

}

@media screen and (max-width: (900px))
// ,
// screen and (max-height: (850px)) 
{
    .notification {
        min-width: 0;

        &::-webkit-scrollbar {
            width: 10px;
            scrollbar-color: #3E71EC #EBF0FF;
            scrollbar-width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #EBF0FF;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #3E71EC;
            border-radius: 3px;
            max-height: 10px;
            min-height: 5px;
            width: 5px;
        }
    }

    .pagination {
        max-width: 90%;
    }

    .filter-profit {
        width: 100%;
    }

    .dashboard-settings {
        display: none !important;
    }

    .notification {
        grid-column: 1 / 12 span;
        padding-left: 35px;

        &--wide {
            grid-column: 1 / 12 span;
        }
        &__table {
            max-height: fit-content;
           // min-height: 500px;
        }
        .navigation {
            display: none;
        }

        .notification-mobile {
            display: block;
        }
    }

    .table {
        background-color: #f4f7ff;
        height: fit-content;
    }

    .table__body {
        padding: 0;
        overflow: hidden;
        height: auto;
        //padding-top: 20px;
        margin: 0;
        // height: 800px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 15px;
        background-color: #f4f7ff;

        &::-webkit-scrollbar-thumb {
            background-clip: padding-box;
        }
    }

    .notification-mobile {
        padding-top: 30px;

        &--scroll-none *> {
            overflow: none;
        }

        &__btn {
            position: relative;
            box-shadow: 0 0 35px 0 #eff3fb;
            background: #fff;
            z-index: 2;
            border-radius: 10px 10px 0 0;
            width: 100%;
            height: 62px;
            cursor: pointer;

            &::after {
                display: block;
                content: '';
                -webkit-mask: url('./../../assets/svg/arrow-down.svg') no-repeat 50% 50%;
                mask: url('./../../assets/svg/arrow-down.svg') no-repeat 50% 50%;
                background-color: #3E5372;
                height: 12px;
                width: 16px;
                position: absolute;
                top: 50%;
                right: 12px;
                transform: translateY(-50%);
                transition: rotate 0.3s;
            }

            &--active {
                &::after {
                    top: 25%;
                    rotate: -180deg;
                    transition: rotate 0.3s;
                }
            }
        }

        .notification-mobile-article {
            padding-left: 10px;
            padding-top: 19px;
            padding-bottom: 19px;
            font-weight: 700;
            font-size: 18px;
            color: #1e2f4a;
        }

        .dashboard-settings {
            padding-left: 10px;
            padding-right: 10px;
            position: relative !important;
            display: block !important;
            padding-top: 15px;
            left: 0;

            z-index: 1;

            &__header {
                display: none;
            }

            &__content {
                width: 100%;
                left: 0;
                box-sizing: border-box;
                padding: 0;
                margin: 0;
                animation: none;
            }

            &::before {
                display: none;
            }
        }
    }

    .notification-mobile-dropdown {
        position: relative;
        width: 100%;
        background: #fff;
        font-weight: 400;
        font-size: 16px;
        color: #3e5372;
        // padding-bottom: 18px;
        margin-bottom: 10px;

        .filter {
           // padding-left: 10px;
            display: block;
            padding-bottom: 0;
            // &__btn {
            //     width: 100%;
            //     max-width: 100%;
            // }
            &__btn {
                margin: 0 auto;
                margin-top: auto;
                margin-bottom: 35px;
                font-weight: 700;
                font-size: 20px;
                color: #fff;
                border-radius: 5px;
                width: 223px;
                height: 50px;
                background: #3e71ec;
                
            }

            
        }

        .filter-left {
            display: flex;
            //width: 60%;
            margin: 0 auto;

            .dropdown {  
                flex-basis: 100%;
                width: 100%;
                max-width: 100%;

                &__btn {
                    width: 100%;
                    max-width: 100%;
                }

                &__list {
                    width: 100%;
                    max-width: 100%;
                }

                &__item {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }

        .filter-right {
            width: 100%;
            max-width: 100%;

            .checkbox-group {
                //gap: 10px;
                justify-content: space-between;
                //width: 60%;
                //margin: 0 auto;
            }
        }
    }
    .checkbox-group {
        flex-direction: row !important;
    }

    .filter,
    .notification__title {
        display: none;
    }

    .notification__connect {
        .notification__title {
            display: block;
        }
    }
}

// @media screen and (max-height: (850px)) {
//     .notification {
//         grid-column: 1 / 12 span;
//         padding-left: 45px;

//         &--wide {
//             grid-column: 1 / 12 span;
//         }

//         .table__body {
//             padding: 0;
//         }

//         .table__header {
//             background-color: #fff;

//             th:last-child {
//                 border-bottom: 2px solid #f4f7ff;
//             }
//         }
//     }

//     .navigation {
//         display: none !important;
//     }
// }

// @media screen and (max-height: (850px)) and (max-width: (1445px)) {
//     .notification {
//         overflow: auto;
//         grid-column: 1 / 12 span;
//         //padding-left: 45px;
//     }
// }



@keyframes pulseFailed {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(236, 62, 62, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(100, 236, 62, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(100, 236, 62, 0);
    }
}