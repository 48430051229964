.spinner-container {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 150;
}

.svg-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 257px;
    height: 257px;
    border-radius: 5px;
    background: #3e71ec;
}


.spinner {
    svg {
        border-radius: 100%;
        -webkit-animation: spin 4s linear infinite;
        -moz-animation: spin 4s linear infinite;
        animation: spin 4s linear infinite;

    }
}

.cross {
    &__article {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 16px;
        font-weight: 700;
        line-height: 21.79px;
        text-align: center;
        color: #fff;
    }

    svg {
        path {
            transform-origin: center;
            //transform: scale(1.1);
            transform-box: fill-box;
            -webkit-animation: crossFade 0.7s ease-out;
            -moz-animation: crossFade 0.7s ease-out;
            animation: crossFade 0.7s ease-out;
        }

    }

    &__line {
        &::before {
            content: "";
            display: block;
            width: 10px;
            height: 2px;
            background-color: #fff;
        }
    }
}

.accept {
    &__article {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 16px;
        font-weight: 700;
        line-height: 21.79px;
        text-align: center;
        color: #fff;
    }
}

.checkmark {
    // display: none;


    &.draw:after {
        animation-duration: 800ms;
        animation-timing-function: ease;
        animation-name: checkmark;
        animation-duration: 2s;
        //animation-iteration-count: infinite;
        transform: scaleX(-1) rotate(135deg);
    }

    &:after {
        position: absolute;
        opacity: 1;
        height: 148px;
        width: 60px;
        transform-origin: left top;
        border-right: 8px solid #fff;
        border-top: 8px solid #fff;
        content: '';
        left: 30%;
        top: 50%;
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}



@keyframes crossFade {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

@keyframes checkmark {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }

    20% {
        height: 0;
        width: 60px;
        opacity: 1;
    }

    // 40% {
    //   height: 60px;
    //   width: 30px;
    //   opacity: 1;
    // }
    100% {
        height: 148px;
        width: 60px;
        opacity: 1;
    }
}