@import '../../App.scss';

.dropdown {
    user-select: none;
    display: inline-block;
    //max-height: 40px;
    font-weight: 400;
    min-height: 30px;
    max-height: 65px;
    font-size: 16px;
    color: #5e779c;

    &--datepicker {
        .dropdown__btn {
            font-weight: 400;
            font-size: 16px;
            color: #5e779c;

            &::after {
                width: 16px;
                height: 16px;
                -webkit-mask: url('./../../assets/svg/dashboard/date.svg') no-repeat 50% 50%;
                mask: url('./../../assets/svg/dashboard/date.svg') no-repeat 50% 50%;
            }

            &--active {
                background-color: #3E71EC;
                color: #fff;

                &::after {
                    background-color: #fff;
                    top: 25%;
                    rotate: 0deg;
                    top: 50%;
                }
            }
        }
    }

    &--little {
        max-width: 131px;

        .dropdown__btn {
            max-width: 131px;
        }

        .dropdown__item {
            max-width: 131px;
        }
    }

    &__article {
        position: relative;
        display: inline-block;
        font-weight: 700;
        width: auto;
        font-size: 16px;
        color: #3e5372;
        padding-bottom: 5px;
        padding-right: 5px;
        // &::after {
        //     content: url('./../../assets/svg/dashboard/question.svg');
        //     width: 14px;
        //     height: 14px;
        //     padding-left: 5px;
        // }
    }

    &__img {
        cursor: pointer;
        fill: #3E5372;
        margin-left: 5px;

        @include onHover {
            fill: #3E71EC;
        }
    }

    &__btn {
        position: relative;
        border-radius: 5px;
        padding-top: 8px;
        padding-left: 9px;
        width: 100%;
        height: 40px;
        background: #ebf0ff;
        cursor: pointer;

        &--little {
            max-width: 131px;
        }

        &::after {
            display: block;
            content: '';
            -webkit-mask: url('./../../assets/svg/arrow-down.svg') no-repeat 50% 50%;
            mask: url('./../../assets/svg/arrow-down.svg') no-repeat 50% 50%;
            background-color: #3E5372;
            height: 12px;
            width: 16px;
            position: absolute;
            top: 50%;
            right: 12px;
            transform: translateY(-50%);
            transition: rotate 0.3s;
        }

        &--active {
            background-color: #3E71EC;
            color: #fff;

            &::after {
                background-color: #fff;
                top: 25%;
                rotate: -180deg;
                transition: rotate 0.3s;
            }
        }
    }

    &__list {
        position: relative;
        margin-top: 1px;
        // display: none;
        height: 40px;
        transform: scaleY(0);
        transition: transform 0.3s ease;
        transform-origin: top center;
        cursor: pointer;
        z-index: 5;

        &--active {
            display: block;
            transition: transform 0.3s ease;
            transform: scaleY(1);
            transform-origin: top center;
            height: fit-content;
            width: 98%;
            margin: 0 auto;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        }
    }

    &__item {
        padding-top: 8px;
        padding-left: 9px;
        width: 282px;
        height: 40px;
        background: #ebf0ff;

        &--little {
            max-width: 131px;
        }

        @include onHover {
            background-color: #3E71EC;
            color: #fff;
            border-radius: 5px;
        }
    }
}

// @include large-laptops {
//     .dropdown {
//         max-width: 135px;

//         &__btn {
//             max-width: 135px;
//         }

//         &__item {
//             max-width: 135px;
//         }
//     }
// }


@media screen and (max-width: (900px)),
screen and (max-height: (850px))  {
    .dropdown {
        &__img {
            display: none;
        }
    }
}