@import '../../App.scss';

.mobile-header {
    display: none;
}

@media screen and (max-width: (900px))
// ,
// screen and (max-height: (850px)) 
{
    .mobile-header {
        grid-column: 1 / 12 span;
        display: block !important;
        position: relative;
        background-color: #3E71EC;
        min-height: 62px;
        max-height: 62px;
        padding-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        z-index: 156;

        .burger-menu-wrapper {
            top: -25px;
            z-index: 156;
        }

        .burger-menu {
            display: block;

            span {
                background-color: #fff;
            }
        }
    }

    .mobile-navigation {
        display: block !important;
        position: fixed;
        left: 0;
        top: 0;
        bottom: -300px;
        right: 0;
        z-index: 4;
        user-select: none;

        .blur {
            z-index: -1;
            position: absolute;
            display: inline-block;
            content: '';
            width: 100%;
            height: 100%;
            backdrop-filter: blur(10.5px);
        }
    }

    .mobile-nav {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: 112px;
        z-index: 6;
        background-color: rgba(41, 98, 234, 0.8);
        height: 100vh;
        animation: menuFadeIn 1.5s;

        &__item {
            display: inline-block;
            width: 100%;
            text-align: center;
            font-weight: 400;
            font-size: 22px;
            color: #fff;
            padding-top: 16px;
            padding-bottom: 16px;
            cursor: pointer;

            &:last-child {
                margin-top: auto;
                margin-bottom: 132px;
            }
        }
        &__guide {
            position: absolute;
            bottom: 40px;
            left: 46%;
            .app-guide {
                margin: 0;
                transform: scale(1.5);
            }
        }
    }
}

@keyframes menuFadeIn {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}