@import './../../App.scss';

.personalize {
    padding-top: 100px;
    padding-bottom: 152px;


    .img-wrapper {
        grid-column: 8 span;
        height: fit-content;
        position: relative;
    }

    &__title {
        padding-left: 75px;
        padding-bottom: 20px;
        grid-column: 1 / 12 span;
        justify-self: end;
        margin: 0;
        font-weight: 700;
        font-size: 116px;
        line-height: 100%;
        text-transform: uppercase;
        color: #3e71ec;
    }

    &__subtitle {
        padding-bottom: 35px;
        grid-column: 10 / 4 span;
        margin: 0;
        font-weight: 700;
        font-size: 54px;
        line-height: 90%;
        text-transform: uppercase;
        color: #3e71ec;
    }

    &__img {
        max-width: 100%;
        grid-column: 8 span;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 15px;
    }

    &__right {
        padding-top: 0px;
        padding-left: 35px;
        grid-column: 9 / 4 span;
    }

    &__descr {
        margin: 0;
        padding: 0;
        margin-bottom: 19px;
        font-weight: 500;
        font-size: 18px;
        color: #617097;
    }

    &__list {
        padding-left: 23px;
        font-weight: 500;
        font-size: 18px;
        color: #697798;
        margin-bottom: 55px;
    }

    &__item {
        position: relative;
        max-width: 394px;

        margin-bottom: 10px;

        &__title {
            margin: 0;
            margin-bottom: 6px;
        }

        &__article {
            margin: 0;
        }

        &::before {
            content: "";
            position: absolute;
            left: -23px;
            top: 14px;
            transform: translateY(-50%);
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: #3e71ec;
        }
    }

    &__btn {
        position: relative;
        border-radius: 5px;
        width: 223px;
        height: 55px;
        background: #8293c7;
        font-weight: 700;
        font-size: 20px;
        color: #fff;
        transition: padding 0.4s ease-in-out;
        overflow: hidden;

        &::after {
            z-index: 3;
            position: absolute;
            top: 56%;
            transform: translateY(-50%);
            right: -35px;
            transition: right 0.4s ease-in-out;
            content: url("./../../assets/svg/btn-arrow.svg")
        }

        @include onHover {
            padding-right: 25px;

            &::after {
                right: 20px;
            }
        }

    }

    @include large-laptops {
        padding-top: 100px;

        &__title {
            padding: 0;
            grid-column: 1 / 12 span;
            margin: 0;
            font-weight: 700;
            font-size: 128px;
            line-height: 100%;
            text-transform: uppercase;
            color: #3e71ec;
        }

        &__subtitle {
            padding-left: 30px;
            grid-column: 8/4 span;
        }


        .img-wrapper {
            grid-column: 7 span;
            
            margin-bottom: 20px;
        }

        &__img {
            grid-column: 7 span;
        }

        &__right {
            //padding-top: 35px;
            padding-top: 0px;
            padding-left: 35px;
            grid-column: 8 / 5 span;
        }
    }

    @include laptops {
        padding-top: 50px;
        padding-bottom: 50px;

        &__title {
            font-size: 84px;
        }

        &__subtitle {
            padding-left: 0;
            font-size: 54px;
            grid-column: 8 / 4 span;
        }

        &__descr {
            font-size: 18px;
        }

        .img-wrapper {
            margin-bottom: 20px;
        }

        &__img {
            grid-column: 7 span;
            width: 95%;
            margin: 0 auto;
        }

        &__right {
            padding-left: 10px;
            width: 85%;
            margin: 0 auto;
            grid-column: 8 / 5 span;
        }
    }

    @include tablet {
        &__article {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__title {
            font-size: 84px;
        }

        &__subtitle {
            font-size: 54px;
            margin-bottom: 30px;
            padding: 0;
            width: 100%;
            text-align: start;
        }

        &__content {
            display: flex;
            flex-direction: column;
        }

        &__list {
            font-size: 16px;
        }

        &__item {
            max-width: 100%;
        }

        &__btn {
            display: block;
            margin: 0 auto;
        }
    }

    @include small-tablet {
        &__title {
            font-size: 38px;
        }

        &__subtitle {
            font-size: 32px;
        }

        &__btn {
            display: block;
            margin: 0 auto;
        }
    }
}