@import '../../App.scss';

.settings-page {
    position: relative;
    grid-column: 3 / 8 span;
    margin-left: -20px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f4f7ff;
    z-index: 3;

    &__article {
        font-weight: 700;
        font-size: 22px;
        color: #1e2f4a;
        margin-bottom: 20px;
    }

    &__content {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        gap: 20px;
        max-height: 700px;
    }

    &__item {
        width: 50%;
        min-height: 510px;
        height: auto;
        border-radius: 5px;
        margin-bottom: 70px;

        // &:first-child {
        //     margin-right: 20px;
        // }
        &--bottom {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 30px;
            margin-bottom: 0px;
            width: 100%;
            max-height: 284px;
            min-height: 240px;
            background-color: #fff;
        }
    }

    &__descr {
        margin: 0;
        width: 467px;
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        color: #3e5372;
    }

    .credentials {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        height: 100%;
        box-shadow: 0 0 35px 0 #eff3fb;
        background: #fff;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;

        &__title {
            margin-bottom: 5px;
            font-weight: 500;
            font-size: 18px;
            color: #3e5372;
        }

        &__input {
            position: relative;
            height: 50px;
            &::before {
                content: "";
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                width: 14px;
                height: 18px;
                left: 17px;
                top: 50%;
                transform: translateY(-50%);
            }

            &::after {
                background-repeat: no-repeat;
                background-position: center;
                background-image: url('./../../assets/svg/dashboard/pen.svg');
                content: "";
                position: absolute;
                width: 18px;
                height: 18px;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
            }

            &--user {
                &::before {
                    background-image: url('./../../assets/svg/auth-user.svg');
                }
            }

            &--password {
                &::before {
                    background-image: url('./../../assets/svg/auth-login.svg');
                }
            }

            &--mail {
                &::before {
                    background-image: url('./../../assets/svg/auth-password.svg');
                }
            }
        }
    }


    &__button {
        position: relative;
        margin: 0 auto;
        margin-top: auto;
        margin-bottom: 34px;
        border-radius: 5px;
        width: 100%;
        height: 50px;
        background: #3e71ec;
        font-weight: 700;
        font-size: 20px;
        color: #fff;
        z-index: 6;

        &--danger {
            margin: 0;
            background: #ff7e7e;
            width: 478px;
            &::after {
                display: none;
            }
            @include onHover {
                transition: background-color 0.3s ease-in-out;
                background-color: #ad1616;
                //border: 2px solid #b41010;
            }
        }

        &::after {
            z-index: -1;
            transition: opacity 2s linear;
            content: "";
            position: absolute;
            border-radius: 5px;
            margin: 0 auto;
            opacity: 0;
            left: -1px;
            top: -1px;
            bottom: -1px;
            right: -1px;
            background: linear-gradient(90deg, #AA3EEC 9%, #6E8FDD 25%, #6E8FDD 56%, #AA3EEC 96%);
        }

        &--filled {
            @include onHover {
                transition: background-color 0.3s ease-in-out;
                background-color: #6E8FDD;
                border: 2px solid #6E8FDD;

                &::after {
                    transition: opacity 2s linear;
                    opacity: 0.7;
                }
            }
        }
    }
}

.contact-support {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    box-shadow: 0 0 35px 0 #eff3fb;
    background: #fff;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    &__descr {
        margin: 0;
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 20px;
        color: #3e5372;
    }

    &__contact {
        display: flex;
        align-items: top;
        gap: 15px;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.01em;
        color: #3e5372;
        margin-bottom: 10px;

        &--mail {
            &::before {
                content: url("./../../assets/svg/dashboard/mail--filled.svg");
                width: 24px;
                height: 20px;
            }
        }

        &--telegram {
            &::before {
                content: url("./../../assets/svg/dashboard/telegram--filled.svg");
                width: 24px;
                height: 24px;
            }
        }
    }

    &__textarea {
        border: none;
        overflow: auto;
        outline: none;
        padding: 15px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        resize: none;
        background: #e2e7f5;
        border-radius: 5px;
        font-weight: 500;
        font-size: 16px;
        //color: #3e5372;
        color: #617097;

        &::-webkit-scrollbar {
            width: 10px;
            scrollbar-color: #3E71EC #EBF0FF;
            scrollbar-width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #EBF0FF;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #3E71EC;
            border-radius: 3px;
            max-height: 10px;
            min-height: 5px;
            width: 5px;
        }
    }
}

.confirm {
    position: relative;
    padding: 50px 52px;

    &__img {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    &__title {
        margin: 0;
        font-weight: 700;
        font-size: 40px;
        line-height: 110%;
        text-transform: uppercase;
        text-align: center;
        color: #3e5372;
        cursor: default;
    }

    .btn-group {
        display: flex;
        align-items: center;
        margin-top: 30px;
    }

    &__btn {
        position: relative;
        border: 2px solid #3E71EC;
        border-radius: 5px;
        min-width: 234px;
        height: 50px;
        font-weight: 700;
        font-size: 20px;
        color: #3e71ec;
        z-index: 6;
        transition: background-color, color 0.3s ease-in-out;

        &--blocked {
            background-color: #EBF0FF !important;
            border: 1px solid #EBF0FF;
            cursor: not-allowed !important;

            &::after {
                display: none;
            }

            @include onHover {
                border: none !important;
            }
        }

        &::after {
            z-index: -1;
            transition: opacity 2s linear;
            content: "";
            position: absolute;
            border-radius: 5px;
            margin: 0 auto;
            opacity: 0;
            left: -0px;
            top: -0px;
            bottom: -0px;
            right: -0px;
            background: linear-gradient(90deg, #AA3EEC 9%, #6E8FDD 25%, #6E8FDD 56%, #AA3EEC 96%);
        }

        @include onHover  {
            background-color: #3E71EC;
            color: #fff;
        }

        &--filled {
            @include onHover {
                transition: background-color 0.3s ease-in-out;
                background-color: #6E8FDD;
                border: 2px solid #6E8FDD;

                &::after {
                    transition: opacity 2s linear;
                    opacity: 0.7;
                }
            }

            background-color: #3E71EC;
            color: #fff;
        }
    }

    .confirm-form {
        &__article {
            font-weight: 500;
            font-size: 18px;
            color: #3e5372;
            margin-bottom: 5px;
        }

        .input-wrapper {
            height: 50px;
            margin-bottom: 15px;
            position: relative;

            &::before {
                background-repeat: no-repeat;
                background-position: center;
                content: "";
                background-image: url("./../../assets/svg/auth-login.svg");
                position: absolute;
                width: 14px;
                height: 18px;
                left: 17px;
                top: 50%;
                transform: translateY(-50%);
            }

            &--login {
                &::before {
                    background-image: url("./../../assets/svg/auth-password.svg");
                    width: 16px;
                    height: 13px;
                }
            }

            &--user {
                &::before {
                    background-image: url("./../../assets/svg/auth-user.svg");
                    width: 13px;
                    height: 14px;
                }
            }

            &--danger {
                border: 1px solid red;
                border-radius: 6px;
            }

            &--confirm {
                position: relative;
                overflow: hidden;

                input {
                    font-weight: 500;
                    font-size: 18px;
                    //color: #93a3d0;
                }

                .confirm-btn {
                    position: absolute;
                    right: 0;
                    border-radius: 5px;
                    width: 140px;
                    height: 50px;
                    background-color: #3e71ec;
                    font-weight: 700;
                    font-size: 18px;
                    color: #fff;


                    &--enterCode {
                        transform: translateX(150%);
                        animation: btnHidden 2s;
                        animation-fill-mode: forwards;
                        display: inline-block;
                        transform: translateY(-150%);
                        user-select: none;
                    }
                }
            }
        }

        &__error {
            display: block;
            margin-top: 5px;
            padding-bottom: 15px;
        }

        &__input {
            outline: none;
            border: none;
            background-color: #e2e7f5;
            border-radius: 5px;
            width: 100%;
            height: 100%;
            font-weight: 500;
            font-size: 18px;
            padding-left: 43px;
            color: #617097;
            &--black {
                color: #3B4B70 !important;
            }
            &::placeholder {
                color: #93a3d0;
            }
        }

        &__link {
            position: relative;
            margin-top: 0;
            display: inline-block;
            font-weight: 600;
            font-size: 16px;
            color: #a8b2c1;
            cursor: pointer;
            margin-bottom: 50px;

            &::before {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 1px;
                bottom: -3px;
                background-color: #A8B2C1;
                transform: scaleX(0);
                transform-origin: top left;
                transition: transform 0.6s ease;
            }

            @include onHover {
                &::before {
                    transform: scaleX(1);
                }
            }
        }
    }
}

.creds-change {
    position: relative;
    padding-top: 50px;
    padding-right: 52px;
    padding-bottom: 50px;
    padding-left: 52px;
    z-index: 5;
    overflow: hidden;

    &__title {
        margin: 0;
        font-weight: 700;
        font-size: 40px;
        line-height: 110%;
        text-transform: uppercase;
        text-align: center;
        color: #3e5372;
        margin-bottom: 41px;
    }

    &__btns {
        margin-top: 50px;
        margin-bottom: 0;
        display: flex;
    }

    &__confirm {
        padding-top: 50px;
        z-index: 4;
        animation: verifyOpen 1s ease-in-out;
    }

    &--animation {
        z-index: 4;
        animation: verifyClose 1s ease-in-out;
    }
}


@keyframes verifyOpen {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes verifyClose {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@include medium-desktop {
    .settings-page {
        padding-bottom: 100px;
        overflow-y: scroll;
        grid-column: 2 / 9 span;

        &__button {
            &_second {
                margin-top: 34px;
            }
        }

        &::-webkit-scrollbar {
            width: 10px;
            scrollbar-color: #3E71EC #EBF0FF;
            scrollbar-width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #EBF0FF;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #3E71EC;
            border-radius: 3px;
            max-height: 10px;
            min-height: 5px;
            width: 5px;
        }

        &__content {
            display: block;
            max-height: fit-content;
        }

        &__item {
            width: 100%;
        }

        .credentials__input {
            &--mail {
                margin-bottom: 20px;
            }
        }

        .contact-support__textarea {
            margin-bottom: 20px;
        }
    }
}

@include medium-desktop {
    .settings-page {
        &__button {
            max-width: 300px;
            width: 300px;

            &__button {
                &_second {
                    margin-top: 34px;
                }
            }

            &--danger {
                width: 80%;
            }
        }
    }
}

@include large-laptops {
    .settings-page {
        grid-column: 2 / 8 span;

        &__button {
            &_second {
                margin-top: 34px;
            }
        }

        &__descr {
            width: auto;
        }

        &__button {
            &--danger {
                width: 80%;
            }
        }
    }
}

@include small-dashboard {
    .settings-page {
        margin-left: 0;
        grid-column: 1 / 12 span;
        overflow-y: auto;

        &__button {
            &_second {
                margin-top: 34px;
            }
        }
    }
    .confirm {
        // padding: 0;
        padding: 20px 15px;
        &__title {
            font-size: 32px;
        }
    }

    .confirm-btn {
        width: 80px !important;
    }
}