@import './../../App.scss';

.info {
    position: relative;
    border-radius: 109px 109px 0 0;
    border: 100%;
    margin-top: -205px;
    margin-bottom: 196px;
    background-color: #fff;
    padding-top: 161px;
    z-index: 2;

    //&::after {
    //    content: '';
    //    position: absolute;
    //    top: 25px;
    //    left: 50%;
    //    transform: translateX(-50%);
    //    width: 0;
    //    height: 0;
    //    border: 6px solid transparent;
    //    border-top: 12px solid #3B4B70;
    //    border-bottom: 0;
    //
    //}

    &__content {
        justify-content: space-between;
        align-items: center;
    }

    &__left {
        width: 50%;
        padding-top: 30px;
    }

    &__article {
        color: #9DAFDD;
        display: flex;
        align-items: center;
        gap: 15px;
        font-size: 18px;
        margin-bottom: 20px;

        &::before {
            display: inline-block;
            content: "";
            height: 1px;
            width: 100px;
            background-color: #9dafdd;
        }
    }

    &__title {
        margin: 0;
        max-width: 574px;
        font-weight: 700;
        font-size: 116px;
        line-height: 90%;
        text-transform: uppercase;
        color: #3e71ec;
    }

    &__subtitle {
        margin: 0;
        max-width: 774px;
        font-weight: 700;
        font-size: 54px;
        text-transform: uppercase;
        color: #3e71ec;
        margin-top: 15px;
    }

    &__descr {
        margin-top: 40px;
        max-width: 630px;
        font-weight: 400;
        font-size: 20px;
        color: #617097;
    }

    &__descr_part {
        margin-bottom: 20px;
    }

    &__right {
        width: 50%;
    }

    &__img {
        float: right;
        max-width: 100%;
    }

    @include large-laptops {
        padding-top: 61px;
        margin-bottom: 96px;
    }

    @include laptops {
        margin-bottom: 60px;
        &__title {
            text-align: left;
            font-size: 120px;
        }
        &__subtitle {
            text-align: left;
            font-size: 83px;
        }
    }
    @include tablet {
        padding-top: 20px;
        border-radius: 30px 30px 0 0;
        &__content {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        &__article {
            display: block;
            &::before {
                display: none;
            }
        }
        &__left {
            width: 100%;
            text-align: center;
        }
        &__right {
            width: 100%;
        }
        &__title {
            font-size: 100px;
            max-width: 100%;
            text-align: center;
        }
        &__subtitle {
            font-size: 64px;
            max-width: 100%;
            text-align: center;
        }
        &__descr {
            //max-width: 100%;
            margin: 0 auto;
            text-align: start;
            font-size: 18px;
        }
        &__img {
            display: block;
            margin: 0 auto;
            float: none;
        }
    }
    @include small-tablet {
        &::after {
            display: none;
        }
        .container {
            padding: 0 9px;
        }
        &__title {
            font-size: 65px;
            max-width: 100%;
            text-align: center;
        }
        &__subtitle {
            font-size: 47px;
            max-width: 100%;
            text-align: center;
        }
        &__descr {
            max-width: 100%;
            text-align: center;
            font-size: 20px;
        }
    }
}