@import './../../App.scss';

iframe {
    width: 100%;
    height: 100%;
    height: 525px;
    border-radius: 15px;
    border: none;
}


.presentation {
    margin: 0 auto;
    border-radius: 109px 109px 0 0;
    padding-bottom: 148px;
    background: #edf3ff;
    justify-content: space-between;

    &__left {
        width: 50%;
        margin-top: 15vh;
    }

    &__right {
        width: 50%;
        margin-top: 130px;
    }

    &__article {
        color: #9DAFDD;
        display: flex;
        align-items: center;
        gap: 15px;
        font-size: 18px;
        margin-bottom: 20px;

        &::before {
            display: inline-block;
            content: "";
            height: 1px;
            width: 100px;
            background-color: #9dafdd;
        }
    }

    &__title {
        margin: 0;
        margin-bottom: 40px;
        font-weight: 700;
        font-size: 116px;
        line-height: 90%;
        text-transform: uppercase;
        color: #3e71ec;
        //padding-right: 30px;
    }

    &__descr {
        margin: 0;
        font-weight: 400;
        font-size: 20px;
        color: #617097;
        max-width: 690px;
    }

    &__img {
        width: 100%;
        max-width: 100%;
        border-radius: 15px;
    }

    @include medium-desktop {
        padding-top: 60px;
        padding-bottom: 60px;

        .container {
            align-items: center;
        }

        &__left {
            width: 50%;
            // margin-top: 50px;
            margin-top: 0px;
        }

        &__right {
            margin-top: 0;
        }

        &__title {
            font-size: 70px;
        }

        &__descr {
            margin: 0;
            font-weight: 400;
            font-size: 18px;
            color: #617097;
            max-width: 520px;
        }
    }

    @include laptops {
        border-radius: 30px 30px 0 0;
        .container {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 30px;
            padding: 0 10px;
            text-align: center;
        }
        &__left {
            width: 100%;
        }
        &__right {
            text-align: center;
            width: 100%;
        }
        &__img {
            margin: 0 auto;
        }
        &__article {
            display: block;
            text-align: center;
            &::before {
                display: none;
            }
        }
        &__title {
         text-align: center;   
        }
        &__descr {
            //max-width: 100%;
            margin: 0 auto;
        }
    }
    @include small-tablet {
        margin: 0;
        &__title {
            font-size: 45px;
        }
        &__descr {
            font-size: 18px;
        }
    }
}

.video-container {
    position: relative;

    .video-btn {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        cursor: pointer;
        z-index: 3;
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        background-color: rgba(0,0,0,0.3);
        border-radius: 15px;
        user-select: none;
        z-index: 2;
    }
}