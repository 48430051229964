@import '../../App.scss';


.navigation {
    padding-top: 35px;
    padding-right: 30px;
    display: flex;
    &__nav {
        margin-left: auto;
    }
    &__list {
        gap: 40px;
    }
    
    &__item {
        position: relative;
        font-weight: 400;
        font-size: 16px;
        color: #7280a4;
        cursor: pointer;
        &::before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 1px;
            bottom: -3px;
            background-color: #A8B2C1;
            transform: scaleX(0);
            transform-origin: top left;
            transition: transform 0.6s ease;
        }

        @include onHover {
            &::before {
                transform: scaleX(1);
            }
        }
    }
}

@include small-dashboard {
    .navigation {
        display: none;
    }
}


