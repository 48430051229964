@import './../../App.scss';

.custom-input {
    margin-top: 20px;

    &__title {
        margin: 0;
        font-weight: 500;
        font-size: 18px;
        color: #3e5372;
        margin-bottom: 10px;
        &--confirm {
            padding-bottom: 45px;
        }
    }

    &__error {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #fd2f2f;
        &--hidden {
            display: none;
        }
        &::before {
            display: inline-block;
            content: '';
            width: 25px;
            height: 1px;
            background-color: #fd2f2f;
        }
        &::after {
            content: "*"
        }
    }

    &__input {
        outline: none;
        border: none;
        background-color: #e2e7f5;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        font-weight: 500;
        font-size: 18px;
        padding-left: 43px;
        color: #617097;

        &::placeholder {
            color: #93a3d0;
        }
    }

    .input-wrapper {
        height: 50px;
        margin-bottom: 15px;
        position: relative;

        &::before {
            background-repeat: no-repeat;
            background-position: center;
            content: "";
            background-image: url("./../../assets/svg/auth-login.svg");
            position: absolute;
            width: 14px;
            height: 18px;
            left: 17px;
            top: 50%;
            transform: translateY(-50%);
        }

        &--login {
            &::before {
                background-image: url("./../../assets/svg/auth-password.svg");
                width: 16px;
                height: 13px;
            }
        }

        &--user {
            &::before {
                background-image: url("./../../assets/svg/auth-user.svg");
                width: 13px;
                height: 14px;
            }
        }

        &--danger {
            border: 1px solid red;
            border-radius: 6px;
        }

        &--confirm {
            position: relative;
            overflow: hidden;

            input {
                font-weight: 500;
                font-size: 18px;
                color: #93a3d0;
            }

            .confirm-btn {
                position: absolute;
                right: 0;
                border-radius: 5px;
                width: 140px;
                height: 50px;
                background-color: #3e71ec;
                font-weight: 700;
                font-size: 18px;
                color: #fff;


                &--enterCode {
                    transform: translateX(150%);
                    animation: btnHidden 2s;
                    animation-fill-mode: forwards;
                    display: inline-block;
                    transform: translateY(-150%);
                    user-select: none;
                }
            }
        }
    }
}

.view-pass {
    position: absolute;
    right: 0;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    margin-bottom: 15px;
    display: block;
    width: 25px;
    height: 17px;
    user-select: none;
    cursor: pointer;
    @include onHover {
        path {
            fill: #3E71EC;
            transition: fill 0.3s;
        }
    }
    &--active {
        path {
        fill: #3E71EC;
        }
        @include onHover {
            path {
                fill: lighten(#3E71EC, 15%)
            }
        }
    }
}