@import './../../App.scss';

.footer {
    background: #0E1117;
    display: flex;
    justify-content: space-between;
    padding: 70px 0;

    &__left {
        grid-column: 4 span;
    }

    &__center {
        padding-top: 23px;
        grid-column: 6 / 3 span;
    }

    &__right {
        padding-top: 100px;
        grid-column: 11 / 2 span;

        .privacy {
            position: relative;
            font-weight: 400;
            font-size: 18px;
            text-decoration-skip-ink: none;
            color: #4d525a;
            cursor: pointer;
            transition: color 0.3s;
            &::before {
                content: "";
                position: absolute;
                display: block;
                width: 112px;
                height: 1px;
                bottom: -3px;
                background-color: #A8B2C1;
                transform: scaleX(0);
                transform-origin: top left;
                transition: transform 0.6s ease;
            }

            @include onHover {
                color: #fff;
                &::before {
                    transform: scaleX(1);
                }
            }
        }
    }

    &__article {
        font-weight: 700;
        font-size: 20px;
        line-height: 110%;
        text-transform: uppercase;
        color: #fff;
    }

    &__descr {
        font-weight: 400;
        font-size: 16px;
        color: #5b6069;
    }

    &__list {
        width: 100%;
        display: flex;
        gap: 15px;
        align-items: center;
    }

    &__item {
        height: auto;
        display: inline-block;

    }

    &__img {
        //object-fit: cover;
        margin-bottom: 73px;
        min-width: 262px;
        height: 28px;
    }

    &__btn {
        border: 1px solid #fff;
        border-radius: 5px;
        width: 280px;
        height: 60px;
        font-weight: 700;
        font-size: 18px;
        color: #fff;
        transition: color, background-color 0.3s;
        @include onHover {
            background-color: #fff;
            color: #3e71ec;
        }
    }

    @include small-tablet {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__descr {
            text-align: center;
        }

        &__left {
            order: 3;
            grid-column: 12 span;
        }

        &__center {
            margin: 0 auto;
            order: 1;
            margin-bottom: 10px;
            grid-column: 12 span;

            img {
                display: block;
                margin-bottom: 40px;
            }
        }

        &__right {
            margin: 0 auto;
            text-align: center;
            padding-top: 20px;
            order: 2;
            grid-column: 12 span;
        }

        &__article {
            display: none;
        }

        &__btn {
            margin-bottom: 20px;
        }
    }
}

.social {
    &__icon {
        cursor: pointer;
        @include onHover {
                path {
                    transition: fill 0.3s;
                    fill: #fff !important;
                }
        }
    }
}

.describe {
    position: relative;
    padding: 50px 100px;
    width: 100%;
    height: 100%;
    
    &__title {
        text-align: center;
        color: #3E71EC;
        text-transform: uppercase;
        font-size: 48px;
        margin-bottom: 45px;
    }
    .input-container {
        position: relative;
        height: 50px; 
        margin-bottom: 15px;

        &::before {
            background-repeat: no-repeat;
            background-position: center;
            content: "";
            background-image: url("./../../assets/svg/auth-password.svg");
            position: absolute;
            width: 14px;
            height: 18px;
            left: 17px;
            top: 50%;
            transform: translateY(-50%);
        }
        min-height: 50px;
    }
    &__input {
        outline: none;
        border: none;
        background-color: #e2e7f5;
        border-radius: 5px;
        width: 100%;
        font-weight: 500;
        font-size: 18px;
        padding-left: 43px;
        color: #617097;
        min-height: 50px;
        &::placeholder {
            color: #93a3d0;
        }
    }

    .textarea-container {
        position: relative;
        &::before {
            background-repeat: no-repeat;
            background-position: center;
            content: "";
            background-image: url('./../../assets//svg/dashboard/pen.svg');
            position: absolute;
            left: 15px;
            top: 15px;
            width: 18px;
            height: 18px;
        }
    }
    
    &__textarea {
        text-indent: 30px;
        width: 100%;
        border: none;
        overflow: auto;
        outline: none;
        padding: 15px;
        box-shadow: none;
        resize: none;
        background: #e2e7f5;
        border-radius: 5px;
        font-weight: 500;
        font-size: 16px;
        //color: #3e5372;
        color: #617097;
        margin-bottom: 20px;
        &::-webkit-scrollbar {
            width: 10px;
            scrollbar-color: #3E71EC #EBF0FF;
            scrollbar-width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #EBF0FF;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #3E71EC;
            border-radius: 3px;
            max-height: 10px;
            min-height: 5px;
            width: 5px;
        }
        &::placeholder {
            color: #93a3d0;
        }
    }

    &__button {
        position: relative;
        margin: 0 auto;
        display: block;
        border-radius: 5px;
        width: 80%;
        height: 55px;
        background: #3E71EC;
        font-weight: 700;
        font-size: 20px;
        color: #fff;
        margin-bottom: 15px;
        overflow: hidden;
        transition: padding 0.4s ease-in-out;

        &::after {
            position: absolute;
            z-index: 3;
            top: 56%;
            transform: translateY(-50%);
            right: -35px;
            transition: right 0.4s ease-in-out;
            content: url("./../../assets/svg/btn-arrow.svg")
        }

        @include onHover {
            padding-right: 25px;

            &::after {
                right: 15px;
            }
        }

        &::before {
            z-index: 2;
            transition: opacity 1s ease-in-out;
            content: "";
            position: absolute;
            border-radius: 5px;
            opacity: 0;
            left: -2px;
            top: -2px;
            bottom: -2px;
            right: -2px;
            background: linear-gradient(90deg, rgba(110, 143, 221, 0.1) 3%, rgba(255, 255, 255, 0.4) 25%, rgba(255, 255, 255, 0.3) 66%, rgba(255, 255, 255, 0.8) 96%);
        }

        @include onHover {
            background-color: #6E8FDD;

            &::before {
                transition: opacity 1s ease-in-out;
                opacity: 0.7;
            }
        }
    }
}

@include tablet {
    .describe {
    padding: 30px 10px;
     &__title {
        font-size: 32px;
        text-align: center;
     }
    }
 }