@import './../../App.scss';

.options {
    padding-top: 130px;
    padding-bottom: 90px;

    &--ru {
        .option__article {
            font-size: 18px;
            line-height: 1.3;
        }

        .option__descr {
            font-size: 16px;
            line-height: 1.65;
        }

        .options__item:nth-child(5) {
            &::after {
                top: 30%;
            }
        }

        .option--right:nth-child(5) {
            &::after {
                top: 50%;
            }
        }

        @media only screen and (max-width: 1700px) {
            .option__article {
                font-size: 17px;
                line-height: 1.3;
            }

            .options__item:nth-child(1) {
                margin-bottom: 10px;
            }

            .options__item:nth-child(2) {
                margin-bottom: 10px;
            }

            .options__item:nth-child(3) {
                margin-bottom: 15px;
            }

            .options__item:nth-child(4) {
                margin-bottom: 25px;
            }

            .option--right:nth-child(1) {
                margin-bottom: 25px;
            }

            .option--right:nth-child(2) {
                margin-bottom: 5px !important;
            }

            .option--right:nth-child(3) {
                margin-bottom: 25px;
            }

            .option--right:nth-child(4) {
                margin-bottom: 25px !important;
            }

            .option__descr {
                font-size: 16px;
                line-height: 1.45;
            }
        }

        @media only screen and (max-width: 1600px) {
            .option__article {
                font-size: 16px;
                line-height: 1.3;
            }

            .option__descr {
                font-size: 16px;
                line-height: 1.35;
            }

            .options__item:nth-child(1) {
                margin-bottom: 25px !important;
            }

            .options__item:nth-child(2) {
                margin-bottom: 25px !important;
            }

            .option--right:nth-child(1) {
                margin-bottom: 30px !important;
            }

            .option--right:nth-child(2) {
                margin-bottom: 15px !important;
            }

            .option--right:nth-child(4) {
                margin-bottom: 15px !important;
            }
        }

        @media only screen and (max-width: 1550px) {
            .options__item:nth-child(1) {
                margin-bottom: 15px !important;
            }

            .option__article {
                font-size: 15px !important;
                line-height: 1.3;
            }

            .option--right {
                padding-top: 15px;
                padding-right: 10px;
            }

            .option--right:nth-child(1) {
                margin-bottom: 30px !important;
            }

            .option--right:nth-child(2) {
                margin-bottom: 10px !important;
            }

            .option--right:nth-child(3) {
                margin-bottom: 15px !important;
            }

            .option--right:nth-child(4) {
                margin-bottom: 25px !important;
            }
        }

        @media only screen and (max-width: 1500px) {
            .options__item {
                padding-bottom: 10px;
            }

            .option--right {
                padding-bottom: 20px;
            }

            .options__item:nth-child(1) {
                margin-bottom: 5px !important;
            }

            .option--right:nth-child(1) {
                margin-bottom: 30px !important;
            }

            .options__item:nth-child(2) {
                margin-bottom: 25px !important;
            }

            .option--right:nth-child(2) {
                margin-bottom: 10px !important;
            }
        }
    }

    .container {
        padding: 0 80px;
    }

    &__content {
        position: relative;
        min-height: 1050px;
        padding-top: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__mobile {
        display: none;
    }


    &__title {
        max-width: 750px;
        margin: 0 auto;
        text-align: center;
        font-weight: 700;
        font-size: 116px;
        line-height: 90%;
        text-transform: uppercase;
        color: #3e71ec;
        margin-top: 120px;
    }

    &__subtitle {
        max-width: 750px;
        margin: 0 auto;
        text-align: center;
        font-weight: 700;
        font-size: 54px;
        line-height: 90%;
        text-transform: uppercase;
        color: #3e71ec;
        margin-bottom: 60px;
        margin-top: 20px;
    }

    &__descr {
        margin: 0;
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        color: #617097;
        margin-bottom: 60px;
    }

    &__grid {
        &--left {
            grid-column: 4 span;
            padding-top: 50px;
        }

        &--center {
            z-index: -1;
            grid-column: 4 / 6 span;
            position: absolute;
            left: 125px;
            top: 60px;
            bottom: 0;

            img {
                z-index: -1;
                position: relative;
                object-fit: cover;
                //max-width: 100%;
                height: 960px;
               // width: 560px;
                box-shadow: 20px 0 81px 0 #e8edf7;
                -webkit-clip-path: inset(-81px -81px -81px 0px);
                clip-path: inset(-81px -81px -81px 0px);
            }
        }

        &--right {
            margin-left: auto;
            padding-left: 50px;
            grid-column: 9 / 4 span;
        }
    }

    &__item {
        max-width: 483px;
        margin-bottom: 30px;
    }

    &__item:nth-child(1) {
        margin-bottom: 35px;
    }

    &__item:nth-child(2) {
        margin-bottom: 60px;
    }

    &__item:nth-child(3) {
        margin-bottom: 20px;
    }

    .option--right:nth-child(1) {
        margin-bottom: 35px;
    }

    .option--right:nth-child(2) {
        margin-bottom: 35px;
    }

    .option--right:nth-child(3) {
        margin-bottom: 30px;
    }

    .option--right:nth-child(4) {
        margin-bottom: 60px;
    }


    &__item:nth-child(4) {
        margin-bottom: 15px;
    }

    &__item:nth-child(5) {
        &::after {
            top: 40%;
        }
    }

    .option--right:nth-child(5) {
        &::after {
            top: 50%;
        }
    }


    @media only screen and (min-width: 2200px) {
        .container {
            padding: 0 120px;
        }
    }

    @media only screen and (max-width: 2000px) {
        .container {
            padding: 0 100px;
        }
    }

    @media only screen and (max-width: 1900px) {
        .container {
            padding: 0 80px;
        }
    }



    @media only screen and (max-width: 1850px) {
        .container {
            padding: 0 65px;
        }

        &__grid {
            &--center {
                left: 110px;
            }
        }

        // &__item:nth-child(4) {
        //     margin-bottom: 15px;
        // }

        // .option--right:nth-child(4) {
        //     margin-bottom: 25px;
        // }
    }

    @media only screen and (max-width: 1800px) {
        .container {
            padding: 0 52px;
        }


        // &__grid {
        //     &--center {
        //         left: 68px;
        //     }
        // }

    }


    @include medium-desktop {
        .container {
            padding: 0 10px;
        }
        &__grid {
            left: 105px;
        }
    }

    @media only screen and (max-width: 1700px) {
        .option--right:nth-child(1) {
            margin-bottom: 65px;
        }
        .option--right:nth-child(2) {
            margin-bottom: 60px;
        }
        .option--right:nth-child(4) {
            margin-bottom: 85px;
        }
        &__item:nth-child(1) {
            margin-bottom: 85px;
        }
        &__item:nth-child(2) {
            margin-bottom: 45px;
        }

        &__grid {
            &--left {
                scale: 0.9;
                padding-top: 90px;
            }
            &--right {
                scale: 0.9;
            }
            &--center {
                top: 100px;
                left: 90px;
            }
        }
    }
    @media only screen and (max-width: 1600px) {
        .container {
            padding: 0;
        }
        .option__article {
                    font-size: 22px;
        }
        &__item:nth-child(1) {
            margin-bottom: 105px;
        }
        .option--right:nth-child(1) {
            margin-bottom: 80px;
        }
        .option--right:nth-child(2) {
            margin-bottom: 80px;
        }
        .option--right:nth-child(4) {
            margin-bottom: 105px;
        }
        &__grid {
            &--left {
                scale: 0.85;
                padding-top: 110px;
            }
            &--right {
                scale: 0.85;
            }
            &--center {
                top: 120px;
            }
        }
    }


    // @media only screen and (max-width: 1700px) {
    //     .container {
    //         padding: 0 30px;
    //     }


    //     &__item {
    //         max-width: 440px;
    //     }

    //     .option--right {
    //         margin-bottom: 20px;
    //     }

    //     &__item:nth-child(4) {
    //         margin-bottom: 10px;
    //     }

    //     .option--right:nth-child(2) {
    //         margin-bottom: 10px;
    //     }

    //     .option--right:nth-child(3) {
    //         margin-bottom: 10px;
    //     }

    //     .option--right:nth-child(4) {
    //         margin-bottom: 20px;
    //     }

    //     &__item:nth-child(5) {
    //         &::after {
    //             top: 40%;
    //         }
    //     }

    //     .option--right:nth-child(5) {
    //         &::after {
    //             top: 50%;
    //         }
    //     }
    // }

    // @media (max-width: 1700px) and (max-height: 850px) {
    //     .option--right:nth-child(1) {
    //         margin-bottom: 25px;
    //     }

    //     .option--right:nth-child(2) {
    //         margin-bottom: 0px;
    //     }

    //     &__grid {
    //         &--center {
    //             left: 50px;
    //         }
    //     }
    // }

    // @media only screen and (max-width: 1650px) {
    //     .container {
    //         padding: 0 20px;
    //     }

    //     &__item:nth-child(2) {
    //         margin-bottom: 30px;
    //     }

    //     &__item:nth-child(3) {
    //         margin-bottom: 15px;
    //     }

    //     &__item:nth-child(4) {
    //         margin-bottom: 10px;
    //     }

    //     .option--right:nth-child(2) {
    //         margin-bottom: 10px;
    //     }

    //     .option--right:nth-child(4) {
    //         margin-bottom: 20px;
    //     }


    //     &__grid {
    //         &--left {
    //             grid-column: 4 span;
    //         }

    //         &--center {
    //             left: 45px;
    //         }
    //     }
    // }

    // @media only screen and (max-width: 1600px) {
    //     .container {
    //         padding: 0 15px;
    //     }

    //     &__grid {
    //         &--center {
    //             left: 35px;
    //         }
    //     }

    //     &__item {
    //         max-width: 410px;
    //     }

    //     .option--right:nth-child(1) {
    //         margin-bottom: 30px;
    //     }

    //     &__item:nth-child(1) {
    //         margin-bottom: 45px;
    //     }

    //     &__item:nth-child(2) {
    //         margin-bottom: 40px;
    //     }

    //     &__item:nth-child(3) {
    //         margin-bottom: 25px;
    //     }

    //     &__item:nth-child(4) {
    //         margin-bottom: 10px;
    //     }

    //     .option--right {
    //         margin-bottom: 24px;
    //     }

    //     .option--right:nth-child(1) {
    //         margin-bottom: 35px;
    //     }

    //     .option--right:nth-child(4) {
    //         margin-bottom: 35px;
    //     }

    //     .option__descr {
    //         font-size: 16px;
    //     }
    // }

    // @media screen and (max-width: 1550px) {
    //     .option__article {
    //         font-size: 18px;
    //     }


    //     &__item {
    //         max-width: 380px;
    //         margin-bottom: 35px;
    //     }

    //     &__item:nth-child(1) {
    //         margin-bottom: 30px;
    //     }

    //     &__item:nth-child(2) {
    //         margin-bottom: 38px;
    //     }

    //     &__item:nth-child(4) {
    //         margin-bottom: 15px;
    //     }

    //     &__item:nth-child(5) {
    //         &::after {
    //             top: 35%;
    //         }
    //     }

    //     .option--right:nth-child(5) {
    //         &::after {
    //             top: 50%;
    //         }
    //     }

    //     .option__descr {
    //         font-size: 16px;
    //     }

    //     .option--right {
    //         margin-bottom: 15px;
    //     }

    //     .option--right:nth-child(1) {
    //         margin-bottom: 17px;
    //     }

    //     .option--right:nth-child(2) {
    //         margin-bottom: 5px;
    //     }

    //     .option--right:nth-child(3) {
    //         margin-bottom: 5px;
    //     }

    //     .option--right:nth-child(4) {
    //         margin-bottom: 25px;
    //     }

    //     &__grid {
    //         &--center {
    //             left: 35px;
    //             top: 30px;
    //         }
    //     }
    // }

    // @media (max-width: 1550px) and (max-height: 850px) {
    //     .option--right:nth-child(1) {
    //         margin-bottom: 35px;
    //     }

    //     .option--right:nth-child(2) {
    //         margin-bottom: 15px;
    //     }

    //     &__grid {
    //         &--center {
    //             left: 30px;
    //         }
    //     }
    // }

    // @media only screen and (max-width: 1500px) {

    //     &__item {
    //         max-width: 360px;
    //         margin-bottom: 20px;
    //         padding-left: 10px;
    //         padding-right: 45px;
    //     }

    //     &__item:nth-child(1) {
    //         margin-bottom: 10px;
    //     }

    //     &__item:nth-child(2) {
    //         margin-bottom: 20px;
    //     }

    //     &__item:nth-child(3) {
    //         margin-bottom: 22px;
    //     }

    //     &__item:nth-child(4) {
    //         margin-bottom: 5px;
    //     }

    //     .option--right:nth-child(1) {
    //         margin-top: 10px;
    //         margin-bottom: 5px;
    //     }

    //     .option--right:nth-child(3) {
    //         margin-bottom: 15px;
    //     }

    //     .option--right:nth-child(4) {
    //         margin-bottom: 25px;
    //     }

    //     .option--right {
    //         margin-bottom: 10px;
    //         padding-right: 10px;
    //         padding-left: 45px;
    //     }

    //     &__grid {
    //         &--center {
    //             top: 40px;
    //             left: 12px;
    //         }
    //     }
    // }

    // @media (max-width: 1500px) and (max-height: 850px) {
    //     .option--right:nth-child(1) {
    //         margin-bottom: 22px;
    //     }

    //     .option--right:nth-child(2) {
    //         margin-bottom: 5px;
    //     }

    //     .option--right:nth-child(3) {
    //         margin-bottom: 5px;
    //     }

    //     .option--right:nth-child(4) {
    //         margin-bottom: 25px;
    //     }

    //     &__item:nth-child(2) {
    //         margin-bottom: 45px;
    //     }

    //     &__grid {
    //         &--center {
    //             left: 7px;
    //         }
    //     }
    // }




    @media only screen and (max-width: 1500px) {
        &__content {
            display: none;
        }

        &__mobile {
            display: block;
            padding-top: 20px;
        }

        &__descr {
            margin-bottom: 20px;
        }

        .filters {
            display: flex;
            flex-wrap: wrap;
            padding-left: 100px;
            padding-right: 100px;
            row-gap: 46px;

            &__item {
                display: block;
                max-width: 400px;
                height: auto;
                border: 2px solid #adbcdf;
                border-radius: 5px;
            }
        }
        
        .filter__img_container {
            overflow: hidden;
        }

        @include tablet {
            padding-top: 40px;

            &__title {
                font-size: 100px;
            }

            &__subtitle {
                font-size: 63px;
            }

            &__descr {
                font-size: 16px;
            }

            .filters {
                padding: 0;
            }

            .container {
                padding: 0 10px;
            }
        }

        .filter {
            position: relative;
            margin: 0 auto;
            display: flex;
            //align-items: center;
            flex-direction: column;
            justify-content: space-between;

            &__underline {
                width: 100%;
                height: 1px;
                background-color: #adbcdf;
                margin-bottom: 30px;
            }
            &__checkbox {
                padding-bottom: 15px;
                cursor: default;
                input {
                    cursor: default;
                }
                .custom-checkbox__title {
                    font-size: 18px;
                    color: #617097;
                }
            }
            &__content {
                padding-top: 15px;
            }
            // &__descr {
            //     &--underline {
            //         position: relative;
            //         &::after {
            //             box-sizing: border-box;
            //             display: block;
            //             content: '';
            //             position: absolute;
            //             left: 0;
            //             width: 100%;
            //             height: 1px;
            //             background-color: #26314c;
            //         }
            //     }
            // }

            &::before {
                content: "";
                position: absolute;
                top: -35px;
                left: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 52px;
                height: 52px;
                transform: translateX(-50%);
                border-radius: 100%;
                background-color: #fff;
                border: 2px solid #adbcdf;
                background-repeat: no-repeat;
                background-position: center;
            }

            &:nth-child(1) {
                img {
                    padding-left: 25px;
                }

                &::before {
                    background-image: url('./../../assets/svg/options/option-6.svg');
                    background-size: 24px 24px;
                }
            }

            &:nth-child(2) {
                img {
                    padding-left: 35px;
                    min-height: 224px;
                }

                &::before {
                    background-image: url('./../../assets/svg/options/option-7.svg');
                    background-size: 24px 24px;
                }
            }

            &:nth-child(3) {
                img {
                    padding-left: 35px;
                }

                &::before {
                    background-image: url('./../../assets/svg/options/option-1.svg');
                    background-size: 21px 21px;
                }
            }

            &:nth-child(4) {
                img {
                    padding-left: 25px;
                }

                &::before {
                    background-image: url('./../../assets/svg/options/option-8.svg');
                    background-size: 22px 24px;
                }
            }

            &:nth-child(5) {
                img {
                    padding-left: 35px;
                }

                &::before {
                    background-image: url('./../../assets/svg/options/option-3.svg');
                    background-size: 24px 24px;
                }
            }

            &__img {
                max-width: 100%;
                max-width: 355px;
                vertical-align: top;
                object-fit: contain;
                box-sizing: border-box;
                // padding-top: 10px;
            }

            &__content {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            &__title {
                font-weight: 700;
                font-size: 16px;
                line-height: 110%;
                text-transform: uppercase;
                text-align: center;
                color: #26314c;
            }

            &__descr {
                font-weight: 400;
                font-size: 14px;
                text-align: center;
                color: #617097;
                padding-left: 20px;
                padding-right: 20px;
                margin-bottom: 30px;
            }
        }
    }

    @include small-tablet {
        &__title {
            font-size: 48px;
        }

        &__subtitle {
            font-size: 36px;
        }
    }

    .filter {
        &__title {
            font-size: 20px;
            margin-bottom: 5px;
        }

        &__descr {
            font-size: 18px;
            margin-top: 0;
            margin-bottom: 30px;
        }
    }
}

.option {
    position: relative;
    padding: 20px 45px 20px 20px;
    border: 2px solid #adbcdf;
    border-radius: 10px;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: -35px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        transform: translateY(-50%);
        border-radius: 100%;
        background-color: #fff;
        border: 2px solid #adbcdf;
        background-repeat: no-repeat;
        background-position: center;
    }

    &::after {
        z-index: 5;
        position: absolute;
        top: 50%;
        right: -295px;
    }

    &:nth-child(1) {
        &::before {
            background-image: url('./../../assets/svg/options/option-1.svg');
        }

        &::after {
            content: url('./../../assets/svg/options/line1.svg');
        }
    }

    &:nth-child(2) {
        &::before {
            background-image: url('./../../assets/svg/options/option-2.svg');
        }

        &::after {
            content: url('./../../assets/svg/options/line2.svg');
        }
    }

    &:nth-child(3) {
        &::before {
            background-image: url('./../../assets/svg/options/option-3.svg');
        }

        &::after {
            content: url('./../../assets/svg/options/line3.svg');
        }
    }

    &:nth-child(4) {
        &::before {
            background-image: url('./../../assets/svg/options/option-4.svg');
        }

        &::after {
            content: url('./../../assets/svg/options/line4.svg');
        }
    }

    &:nth-child(5) {
        &::before {
            background-image: url('./../../assets/svg/options/option-5.svg');
        }

        &::after {
            content: url('./../../assets/svg/options/line5.svg');
        }
    }

    &--right {
        padding: 20px 20px 20px 50px;

        &::before {
            right: 0;
            left: -35px;
        }

        &::after {
            right: 0%;
            left: -290px;
        }

        &:nth-child(1) {
            &::before {
                background-image: url('./../../assets/svg/options/option-6.svg');
            }

            &::after {
                content: url('./../../assets/svg/options/line6.svg');
            }
        }

        &:nth-child(2) {
            &::before {
                background-image: url('./../../assets/svg/options/option-7.svg');
            }

            &::after {
                content: url('./../../assets/svg/options/line7.svg');
            }
        }

        &:nth-child(3) {
            &::before {
                background-image: url('./../../assets/svg/options/option-8.svg');
            }

            &::after {
                content: url('./../../assets/svg/options/line8.svg');
                left: -180px;
            }
        }

        &:nth-child(4) {
            &::before {
                background-image: url('./../../assets/svg/options/option-9.svg');
            }

            &::after {
                content: url('./../../assets/svg/options/line9.svg');
                left: -144px;
            }
        }

        &:nth-child(5) {
            &::before {
                background-image: url('./../../assets/svg/options/option-10.svg');
            }

            &::after {
                content: url('./../../assets/svg/options/line10.svg');
                left: -190px;
            }
        }
    }

    &__article {
        margin: 0;
        margin-bottom: 15px;
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        text-transform: uppercase;
        color: #3560c7;
    }

    &__descr {
        margin: 0;
        font-weight: 400;
        font-size: 18px;
        color: #617097;
    }
}