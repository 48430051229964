@import '../../App.scss';

.dashboard-settings {
    grid-column: 11 / 2 span;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    background-color: #fff;

    &__content {
        padding-top: 30px;
        padding-right: 15px;
        //padding-right: -30px;
        //margin-right: -30px;
        padding-left: 15px;
        background-color: #fff;
        height: 100%;
        //padding-bottom: 300px;
    }

    &::-webkit-scrollbar {
        width: 10px;
        scrollbar-color: #3E71EC #EBF0FF;
        scrollbar-width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #EBF0FF;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #3E71EC;
        border-radius: 3px;
        max-height: 10px;
        min-height: 5px;
        width: 5px;
    }

    &--toggle {
        display: none;
    }

    &__header {
        font-weight: 700;
        font-size: 22px;
        color: #1e2f4a;
        margin-bottom: 15px;
    }

    &__article {
        position: relative;
        font-weight: 700;
        font-size: 18px;
        color: #3e5372;
        margin-bottom: 10px;
        margin-top: 25px;

        &--disabled {
            color: #8790a8;
        }
    }

    &__range {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        cursor: pointer;
        outline: none;
        background-color: #DADFE8;
        height: 4px;
        border-radius: 71px;

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background-color: #3E71EC;
        }

        &::-moz-range-thumb {
            -webkit-appearance: none;
            appearance: none;
            height: 12px;
            width: 12px;
            background-color: #3E71EC;
            border-radius: 50%;
        }

        &::-webkit-slider-runnable-track {
            height: 10px;
            -webkit-appearance: none;
            color: #13bba4;
            margin-top: -1px;
        }
    }

    // &__btn {
    //     margin: 0 auto;
    //     margin-top: 35px;
    //     margin-bottom: 35px;
    //     font-weight: 700;
    //     font-size: 20px;
    //     color: #fff;
    //     border-radius: 5px;
    //     width: 100%;
    //     min-height: 50px;
    //     background: #3e71ec;


    //     &:hover {
    //         transition: background-color 0.3s ease-in-out;
    //         background-color: #6E8FDD;
    //         border: 2px solid #6E8FDD;
    //     }

    //     &::after {
    //         z-index: -1;
    //         transition: opacity 2s linear;
    //         content: "";
    //         position: absolute;
    //         border-radius: 5px;
    //         margin: 0 auto;
    //         opacity: 0;
    //         left: -2px;
    //         top: -2px;
    //         bottom: -2px;
    //         right: -2px;
    //         background: linear-gradient(90deg, #AA3EEC 9%, #6E8FDD 25%, #6E8FDD 56%, #AA3EEC 96%);
    //     }
    // }
    &__btn {
        position: relative;
        border: 2px solid #3E71EC;
        border-radius: 5px;
        padding: 12px 57px;
        font-weight: 700;
        font-size: 20px;
        color: #3e71ec;
        z-index: 6;
        transition: background-color, color 0.3s ease-in-out;
        width: 100%;
        @include onHover {
            background-color: #3E71EC;
            color: #fff;
        }

        &::after {
            z-index: -1;
            transition: opacity 2s linear;
            content: "";
            position: absolute;
            border-radius: 5px;
            margin: 0 auto;
            opacity: 0;
            left: -2px;
            top: -2px;
            bottom: -2px;
            right: -2px;
            background: linear-gradient(90deg, #AA3EEC 9%, #6E8FDD 25%, #6E8FDD 56%, #AA3EEC 96%);
        }

        &--filled {
            @include onHover {
                transition: background-color 0.3s ease-in-out;
                background-color: #6E8FDD;
                border: 2px solid #6E8FDD;

                &::after {
                    transition: opacity 2s linear;
                    opacity: 0.7;
                }
            }

            background-color: #3E71EC;
            color: #fff;
        }
        &--disabled {
            cursor: not-allowed;
        }
    }
}

.custom-checkbox {
    position: relative;
    // display: grid;
    // grid-template-columns: 35px auto;
    display: flex;
    gap: 5px;
    cursor: pointer;
    user-select: none;

    &__field {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
        width: 24px;
        height: 24px;
        border: none;
        border-radius: 5px;
        display: grid;
        place-content: center;
        background-color: #EBF0FF;
        transition: background-color 0.1s;
        cursor: pointer;

        &:checked {
            background-color: #3E71EC;
        }

        &::before {
            display: block;
            content: "";
            width: 15px;
            height: 15px;
            background-color: #EBF0FF;
            -webkit-mask: url('./../../assets/svg/dashboard/checked.svg') no-repeat 50% 50%;
            mask: url('./../../assets/svg/dashboard/checked.svg') no-repeat 50% 50%;
        }

        &:checked::before {
            content: "";
            background-color: #fff;
        }
    }

    &__title {
        align-self: center;

        &--disabled {
            color: #8790a8;
        }
    }
}

.exchanges {
    &__search {
        position: relative;
        width: 100%;
        height: 35px;
        margin-bottom: 10px;

        &::before {
            content: url('./../../assets/svg/dashboard/search.svg');
            top: 50%;
            transform: translateY(-50%);
            left: 7px;
            position: absolute;
        }

        input {
            width: 100%;
            height: 100%;
            border: none;
            background: #ebf0ff;
            border-radius: 5px;
            font-weight: 400;
            font-size: 14px;
            color: #5e779c;
            padding-left: 33px;
        }
    }

    &__list {
        height: 125px;
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-bottom: 15px;

        &::-webkit-scrollbar {
            width: 10px;
            scrollbar-color: #3E71EC #EBF0FF;
            scrollbar-width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #EBF0FF;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #3E71EC;
            border-radius: 3px;
            max-height: 10px;
            min-height: 5px;
            width: 5px;
        }
    }

    &__item {
        margin-bottom: 6px;

        &--skeleton {
            padding-right: 15px;
        }

        &--skeleton>* {
            cursor: default;
        }
    }
}

.profit {
    padding-right: 10px;

    &__article {
        margin-bottom: 0;
    }

    &__range {
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 16px;
        color: #5e779c;
    }

    margin-bottom: 15px;
}

.amount {
    padding-right: 10px;

    &__article {
        margin-bottom: 0;
    }

    margin-bottom: 15px;
}

.dashboard-options {
    padding-right: -70px;

    &__item {
        //max-width: 220px;
        display: flex;
        position: relative;
        margin-bottom: 10px;
        min-height: 25px;
        max-height: 25px;
    }
}

.burger-menu-wrapper {
    position: absolute;
    right: 15px;
    top: -12px;
    z-index: 106;
}

.burger-menu {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 50px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #1e2f4a;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2) {
            top: 8px;
        }

        &:nth-child(3) {
            top: 8px;
        }

        &:nth-child(4) {
            top: 16px;
        }
    }

    &--open {
        span {
            &:nth-child(1) {
                top: 16px;
                width: 0%;
                left: 50%;
            }

            &:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: 16px;
                width: 0%;
                left: 50%;
            }
        }
    }
}

// @media screen and (max-height: 950px) {
//     .dashboard-settings {
//         padding-bottom: 300px;
//     }
// }

// @media screen and (max-height: 600px){
//     .dashboard-settings {
//         padding-bottom: 600px;
//     }
// }

// @media screen and (max-width: 1660px) {
//     .dashboard-options {
//         &__item {
//             max-width: 160px;
//         }
//     }
// }

// @include large-laptops {
//     .dashboard-settings {
//         position: absolute;
//         left: -20px;
//         top: 0;
//         right: 0;
//         bottom: 0;
//         grid-column: 2 / 12 span;
//         z-index: 105;
//         padding-top: 0;

//         &::before {
//             z-index: 5;
//             position: absolute;
//             content: "";
//             top: 0;
//             left: -5px;
//             bottom: -200px;
//             width: 100%;
//             //backdrop-filter: blur(25px);
//             animation: settingsBlur 1s forwards;
//             background: url("./../../assets/svg/kairospeak-logo.svg") no-repeat;
//             background-position: 35% 35%;
//         }

//         &__content {
//             display: flex;
//             flex-direction: column;
//             margin-left: auto;
//             width: 343px;
//             height: 100%;
//             background-color: #fff;
//             z-index: 105;
//             padding-left: 30px;
//             padding-top: 30px;
//             animation: settingsFadeIn 1.5s;
//         }
//         &__header {
//             margin-bottom: 32px;
//         }
//         &__article {
//             margin-bottom: 5px;
//         }
//     }
// }

@media screen and (max-width: (900px))
// ,
// screen and (max-height: (850px)) 
{
    .burger-menu {
        display: none;
    }

    .dashboard-settings {
        &__content {
            display: flex;
            flex-direction: column;
            margin-left: auto;
            width: 343px;
            height: 100%;
            background-color: #fff;
            z-index: 105;
            padding-left: 30px;
            padding-top: 30px;
            animation: settingsFadeIn 1.5s;
        }
        &__btn {
            width: 223px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
}



@keyframes settingsFadeIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0%);
    }

}

@keyframes settingsBlur {
    from {
        backdrop-filter: blur(0px);
    }

    to {
        backdrop-filter: blur(25px);
    }
}