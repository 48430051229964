@import './../../App.scss';

.filter {
    width: 100%;
    // height: 174px;
    background-color: #fff;
    border-radius: 5px;
    padding: 15px 20px;
    display: flex;
    justify-content: space-around;

    &__btn {
        position: relative;
        border: 2px solid #3E71EC;
        border-radius: 5px;
        margin-top: auto;
        border-radius: 5px;
        //width: 281px;
        height: 40px;
        font-weight: 700;
        font-size: 20px;
        color: #3e71ec;
        z-index: 6;
        transition: background-color, color 0.3s ease-in-out;
        @include onHover {
            background-color: #3E71EC;
            color: #fff;
        }

        &::after {
            z-index: -1;
            transition: opacity 2s linear;
            content: "";
            position: absolute;
            border-radius: 5px;
            margin: 0 auto;
            opacity: 0;
            left: -2px;
            top: -2px;
            bottom: -2px;
            right: -2px;
            background: linear-gradient(90deg, #AA3EEC 9%, #6E8FDD 25%, #6E8FDD 56%, #AA3EEC 96%);
        }

        &--filled {
            @include onHover {
                transition: background-color 0.3s ease-in-out;
                background-color: #6E8FDD;
                border: 2px solid #6E8FDD;

                &::after {
                    transition: opacity 2s linear;
                    opacity: 0.7;
                }
            }

            background-color: #3E71EC;
            color: #fff;
        }
        &--disabled {
            cursor: not-allowed;
        }
    }
}

.filter-left {
    // padding-bottom: 10px;
    display: flex;
    gap: 20px;
    row-gap: 15px;
    flex-wrap: wrap;
    width: 100%;
}

.filter-right {
    padding-top: 30px;
    //width: 45%;
    display: flex;
    flex-direction: column;
    //gap: 20px;
    gap: 10px;
    row-gap: 10px;

    .checkbox-group {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        row-gap: 6px;
    }
}

.filter-profit {
    display: inline-block;
    width: 14.4%;

    &__article {
        font-weight: 700;
        font-size: 16px;
        color: #3e5372;
        padding-bottom: 5px;

        &::after {
            content: url('../../assets/svg/dashboard/question.svg');
            width: 14px;
            height: 14px;
            padding-left: 5px;
        }
    }

    &__counter {
        border-radius: 5px;
        //width: 131px;
        width: 100%;
        height: 40px;
        background: #ebf0ff;
    }

    &__input {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        //width: 131px;
        height: 40px;
        background: #ebf0ff;
        border: none;
        padding-left: 10px;
        font-weight: 400;
        font-size: 16px;
        color: #5e779c;

        &:focus {
            outline: none;
        }
    }
}

@media screen and (max-height: 850px) {
    .filter {
        height: auto;
    }

}

@media screen and (max-width: 1900px) {
    .filter {
        height: auto;
        // padding-bottom: 45px;

        .filter-left {
            row-gap: 30px;
            justify-content: center;
        }

        .filter-right {
            //width: 35%;
            justify-content: start;
            // padding-bottom: 10px;

            .checkbox-group {
                flex-direction: column;
            }
        }

        &__btn {
            width: 150px;
        }
    }
}