//@include font-face("OpenSansRegular", "./assets/fonts/OpenSans-Regular.ttf", 400, normal);


@font-face {
    font-family: 'OpenSans';
    src: url("./assets/fonts/OpenSans-Regular.ttf") format('TrueType');
    font-weight: 400;
}

@font-face {
    font-family: 'OpenSans';
    src: url("./assets/fonts/OpenSans-Medium.ttf") format('TrueType');
    font-weight: 500;
}

@font-face {
    font-family: 'OpenSans';
    src: url("./assets/fonts/OpenSans-SemiBold.ttf") format('TrueType');
    font-weight: 600;
}

@font-face {
    font-family: 'OpenSans';
    src: url("./assets/fonts/OpenSans-Bold.ttf") format('TrueType');
    font-weight: 700;
}