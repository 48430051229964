@import './../../App.scss';

.rates {
    padding-bottom: 250px;

    &__title {
        margin: 0;
        text-align: center;
        font-weight: 700;
        font-size: 116px;
        line-height: 90%;
        text-transform: uppercase;
        color: #3e71ec;
    }

    &__subtitle {
        margin: 0;
        margin-top: 20px;
        text-align: center;
        font-weight: 700;
        font-size: 54px;
        line-height: 110%;
        text-transform: uppercase;
        color: #3e71ec;
        margin-bottom: 80px;
    }

    @include tablet {
        padding-bottom: 100px;
    }

    @include small-tablet {
        .container {
            padding: 0 10px;
        }

        &__title {
            font-size: 48px;
        }

        &__subtitle {
            font-size: 32px;
            margin-bottom: 30px;
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}


.card {
    grid-column: 4 span;
    box-shadow: 0 0 79px 0 #eaf1ff;
    align-self: start;
    padding-bottom: 20px;

    &__header {
        font-weight: 700;
        font-size: 20px;
        line-height: 110%;
        text-transform: uppercase;
        color: #fff;
        background: #8293c7;
        border-radius: 10px;

        &--BASIC {
            background-color: #3e71ec;
        }

        &--PREMIUM {
            background-color: #b8a9ef;
        }
    }

    &__article {
        text-align: center;
        padding: 32px 0;
    }

    &__descr {
        margin: 0;
        font-weight: 500;
        font-size: 20px;
        color: #617097;
        padding-top: 30px;
        padding-left: 25px;
        padding-right: 25px;
        margin-bottom: 40px;
    }

    &__list {
        padding-left: 58px;
        padding-right: 58px;
        font-weight: 400;
        font-size: 20px;
        color: #697798;
        margin-bottom: 55px;
    }

    &__item {
        position: relative;
        margin-bottom: 10px;

        &::before {
            content: "";
            position: absolute;
            left: -23px;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: #3e71ec;
        }
    }

    &__support {
        margin: 0;
        margin-top: -20px;
        margin-bottom: 15px;
        padding-left: 20px;
        font-weight: 700;
        font-size: 20px;
        color: #5d719f;
    }
    


    &__button {
        position: relative;
        margin: 0 auto;
        display: block;
        border-radius: 5px;
        width: 223px;
        height: 55px;
        background: #8293c7;
        font-weight: 700;
        font-size: 20px;
        color: #fff;
        margin-bottom: 15px;
        overflow: hidden;
        transition: padding 0.4s ease-in-out;

        &::after {
            position: absolute;
            z-index: 3;
            top: 56%;
            transform: translateY(-50%);
            right: -35px;
            transition: right 0.4s ease-in-out;
            content: url("./../../assets/svg/btn-arrow.svg")
        }

        @include onHover {
            padding-right: 25px;

            &::after {
                right: 15px;
            }
        }

        &::before {
            z-index: 2;
            transition: opacity 1s ease-in-out;
            content: "";
            position: absolute;
            border-radius: 5px;
            opacity: 0;
            left: -2px;
            top: -2px;
            bottom: -2px;
            right: -2px;
            background: linear-gradient(90deg, rgba(110, 143, 221, 0.1) 3%, rgba(255, 255, 255, 0.4) 25%, rgba(255, 255, 255, 0.3) 66%, rgba(255, 255, 255, 0.8) 96%);
        }

        @include onHover {
            background-color: #6E8FDD;

            &::before {
                transition: opacity 1s ease-in-out;
                opacity: 0.7;
            }
        }

        &--BASIC {
            background-color: #3e71ec;
        }

        &--PREMIUM {
            background-color: #a382ea;

            @include onHover {
                background-color: #a382ea;
            }
        }
    }

    &__old-price {
        display: block;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        text-decoration: line-through;
        color: #3e5372;
    }

    &__activeSub-descr {
        text-align: center;
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        color: #617097;
    }

    @media screen and (max-width: 1090px) {
        grid-column: 2 / 10 span;
        margin-bottom: 20px;

        &__descr {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    @include small-tablet {
        grid-column: 12 span !important;

        &__header {
            font-size: 18px;
        }

        &__descr {
            font-size: 18px;
        }

        &__list {
            font-size: 18px;
        }
    }
}

.price-list {
    padding-left: 20px;
    padding-bottom: 50px;

    .price {
        &__title {
            font-weight: 400;
            font-size: 18px;
            color: #3e5372;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 12.5px;
        }

        input[type="radio"] {
            display: none !important;
        }

        .custom-checkbox {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            background: #ebf0ff;
            border-radius: 100%;
            cursor: pointer;
        }

        input[type="radio"]:checked+.custom-checkbox {
            &::after {
                content: "";
                display: inline-block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: rgb(62, 113, 236);
            }
        }
    }
}