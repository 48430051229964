@import '../../App.scss';

.choose-sub-container {
    position: relative;
    background-image: url('./../../assets/img/auth-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 100vw;
    min-height: 100vh;
    overflow: hidden;
}

.choose-sub {
    &__title {
        font-weight: 700;
        font-size: 40px;
        line-height: 110%;
        text-transform: uppercase;
        text-align: center;
        color: #3e5372;
        padding-bottom: 50px;
    }

    &__skip {
        position: absolute;
        top: 40px;
        right: 50px;
        font-weight: 700;
        font-size: 14px;
        line-height: 110%;
        text-transform: uppercase;
        text-align: center;
        color: #3e5372;
        cursor: pointer;
        margin-bottom: 26px;
        overflow: hidden;
        &::after {
            display: block;
            content: url('./../../assets/svg/skip-arrow.svg');
            transform: translateX(-100%);
            transition: transform 1s;
        }
        @include onHover {
            &::after {
                transform: translateX(0);
            }
        }
    }

    &__buy {
        padding-left: 200px;
        padding-right: 200px;
        padding-bottom: 100px;
    }

    .card {
        background-color: #fff;
        border-radius: 15px 15px 5px 5px;
    }
}

@include large-laptops {
    .choose-sub {
        &__title {
            max-width: 500px;
            margin: 0 auto;
            padding-top: 80px;
        }
        &__buy {
            padding-left: 100px;
            padding-right: 100px;
        }
    }
}

@include tablet {
    .choose-sub {
        &__buy {
            padding-left: 0;
            padding-right: 0;
        }
        .card {
            grid-column: 2 / 10 span;
        }
    }
}